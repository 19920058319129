import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import axios from '../_api/axios';

import Maintenance from './maintenance/Maintenance';
import CommingSoon from './status/CommingSoon'

const Layout = () => {

  const [siteSettings, setSiteSettings] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Checking if the site is under any modes
  // or currently in an unavailable state
  useEffect(() => {
    let isMounted = true;

      const checkSiteStatus = async (url) => {
        try {
          setIsLoading(true);

            if(isMounted) {
              // Checking if site is in any mode
              const response = await axios.post(url);

                if(response.data) {
                  // Store site settings
                  setSiteSettings(response.data);
                }
            }

        }
        catch(err) {
          if(isMounted) {
            if(!err?.response) {
              console.error(!err);
            }
            setIsLoading(false);
          }
        }
        finally {
          setIsLoading(false);
          isMounted = false;
        }
      }

        checkSiteStatus('/check');

      const cleanUp = () => {
          isMounted = false;
      }

        return cleanUp;
  }, []);

    return (
      <>
        {
          !isLoading &&
          siteSettings.maintenance
          // So that we don't see status pages
          // in development
          // process.env.NODE_ENV === 'production'
            ? siteSettings.commingsoon 
              ? !window.localStorage.getItem('noCommingSoon') 
                  ? <CommingSoon /> 
                  : <Outlet />
              : <Maintenance /> 
          : <Outlet />
        }
      </>
    )
};

export default Layout;