import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { useLoadingContext } from 'react-router-loading';
import { useTranslation } from 'react-i18next';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';
import AnimatedCounter from '../_helpers/AnimatedCounter';
import Header from '../_components/Header';
import Footer from '../_components/Footer';
import * as Icon from 'react-bootstrap-icons';
import { axiosPrivate } from '../_api/axios';
import useAuth from '../_hooks/useAuth';
import UserMenu from '../_components/UserMenu';
import Pagination from '../_components/Pagination';
import ErrorHandler from '../_helpers/ErrorHandler';
import PrPageSelect from '../_helpers/PrPageSelect';
import { formatDate, itemsPrPage, documentHeight } from '../_helpers/Utils';

const Payments = () => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const loadingContext = useLoadingContext();
  const [userCredit, setUserCredit] = useState(0);
  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [allPayments, setAllPayments] = useState([]);
  const [currentPayments, setCurrentPayments] = useState([]);

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
      const newOffset = (event.selected * itemsPrPage()) % allPayments.length;
        await setItemOffset(newOffset);
          await setCurrentPayments(allPayments.slice(newOffset, newOffset + itemsPrPage()));
            if(itemsPrPage() !== 4 && itemsPrPage() !== 8) window.scrollTo(0, 0);
  }

    useEffect(() => {
      let isMounted = true;
        const getUserPayments = async (url) => {
          setIsLoading(true);
            try {
              const response = await axiosPrivate.get(url, {
                 headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${auth?.accessToken}`,
                 },
                 withCredentials: true
              });

                if (isMounted) {
                    setUserCredit(response.data.credit);
                    setAllPayments(response.data.payments);

                    const endOffset = itemOffset + itemsPrPage();
                    setCurrentPayments(response.data.payments.slice(itemOffset, endOffset));
                    setFetchError(null);
                    setLoadedOnce(true);
                }
                
                loadingContext.done();
            } catch(err) {

                if (isMounted) {
                    setFetchError(err.message);
                    setUserCredit(null);
                    setAllPayments([]);
                    setUserCredit(0);
                    setLoadedOnce(false);
                    window.scrollTo(0, 0);
                }
            } finally {
                setIsLoading(false);
                isMounted = false;
            }
        }

        if(!loadedOnce) {
          getUserPayments(`${process.env.REACT_APP_BACKEND_URL}/user/payments`);
        }

        const cleanUp = () => {
            isMounted = false;
        }

        return cleanUp;
    }, [fetchError, auth?.accessToken, loadingContext, itemOffset, loadedOnce]);

  return (
    <>
      <Header />
        <div id="header_static" className="default-bg container-fluid product single top-bg">
          <div className="product_top d-none d-lg-block">
            <h1>{t("Payments").toUpperCase()}</h1>
          </div>

        <section>
          <MDBContainer className="py-4">
            <MDBRow>
              <MDBCol>
                <MDBBreadcrumb className="content-nav bg-dark p-3 mb-4 breadcrumb danger">
                  <MDBCol className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                    <MDBBreadcrumbItem>
                      <a href='/'>{t("Home")}</a>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>
                      {t("Payments")}
                    </MDBBreadcrumbItem>
                  </MDBCol>
                  <MDBCol className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                    <PrPageSelect className="me-2" handlePageClick={handlePageClick} />
                  </MDBCol>
                </MDBBreadcrumb>
                <ErrorHandler error={fetchError} />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg="3">

                <MDBCard className="mb-4">
                  <MDBCardBody className="text-center wallet">
                    <p className="text-muted mb-1 mt-5">{t("Credit")}</p>
                    <p className="text-muted mb-4"><b className="text-white">
                      {
                        !isLoading 
                          ? <><AnimatedCounter from={0} to={userCredit} /> kr</>
                          : t("LoadingContent")
                      }
                      </b>
                    </p>
                    <div className="d-flex justify-content-center mb-2">
                      <a href="/charge" className="btn btn-success"><Icon.CreditCard2FrontFill className="me-1" /> {t("ChargeHere")}</a>
                    </div>
                  </MDBCardBody>
                </MDBCard>

                <UserMenu />
              </MDBCol>

              <MDBCol lg="9" style={{ minHeight: `${documentHeight()}px` }}>
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <MDBContainer className="text-white">
                      { isLoading ? <div className="col-md-8 mb-4"><p className="statusMsg">{t("LoadingContent")}</p></div> : '' }
                      { !isLoading &&
                        currentPayments.length === 0 ? <p className="text-fade text-center m-0">{t("NoPaymentsToShow")}</p> : '' }
                        
                      { !isLoading &&
                        currentPayments.length !== 0 &&
                        currentPayments.map((value, index) => {
                          const fdate = t("DateFormat", { date: formatDate(value.created) });

                            return <motion.div
                                      variants={{
                                        hidden: { opacity: 0, x: 100 },
                                        visible: { opacity: 1, x: 0 }
                                      }}
                                      initial="hidden"
                                      animate="visible"
                                      className="row col-12"
                                      transition={{ duration: 0.5, delay: (index / 15) }}
                                      key={index}>
                                    <MDBCol size="12" className="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                      <p className={`mb-1 text-${ value.income ? 'success' : 'danger'}`}>{t(value.status.replace(' ', ''))}</p>
                                      <p>{fdate}</p>
                                    </MDBCol>
                                    <MDBCol size="12" className="col-xs-2 col-sm-2 col-md-2 col-lg-3">
                                      <h2 className="align-middle"><small className="me-2 m-0">{t("Account")}: </small>{value.credit}</h2>
                                    </MDBCol>
                                    <MDBCol size="2" className="col-xs-1 col-sm-1 col-md-1 col-lg-1 d-md-block">
                                      <Icon.CreditCard2FrontFill className="fs-1 text-white" />
                                    </MDBCol>
                                    <MDBCol size="10" className="col-xs-2 col-sm-2 col-md-1 col-lg-2">
                                      <h2 className="text-white">{value.price}</h2>
                                    </MDBCol>
                                    <MDBCol size="2" className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                      <Icon.ArrowRight className={`fs-1 text-${value.income ? 'success' : 'danger'}`} />
                                    </MDBCol>
                                    <MDBCol size="10" className="col-xs-2 col-sm-2 col-md-1 col-lg-2">
                                      { 
                                        value.income 
                                          ? <h2 className="text-success">{(value.credit + value.price)}</h2>
                                          : <h2 className="text-danger">{(value.credit - value.price)}</h2>
                                      }
                                    </MDBCol>
                                    <hr />
                                  </motion.div>
                        })
                      }
                    </MDBContainer>
                  </MDBCardBody>
                </MDBCard>
                {
                  !isLoading &&
                  allPayments.length > itemsPrPage() && (
                    <Pagination
                      items={allPayments}
                      itemsPerPage={itemsPrPage()}
                      itemOffset={itemOffset}
                      handlePageClick={handlePageClick}
                    />
                  )
                }
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
        
        </div>

      <Footer />
    </>
  )
};

export default Payments;