import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { minTotalByPrice } from './Utils';
import AnimatedCounter from '../_helpers/AnimatedCounter';

/**
 * ---------------------------------------------------
 * Product cards for every pages
 * ---------------------------------------------------
 * These cards are used over the entire site, to make
 * it more dynamic, so that we only have to change 
 * them in one place.
 * They can show discount, or hide buy btn and tags.
 * Every product have a template, they can load their
 * data into. 
 * This means that they can change the way they look
 * from product to product.
 * ---------------------------------------------------
 */

const FrontImgProductWrapper = (value) => {
  const { t }    = useTranslation();
  const data     = value.value ? value.value : value;
  const delay    = value.delay;
  const newClass = data.headerclass.split('-')[0];
  const minPrice = minTotalByPrice(data.packages, data.title);

  let template = <div className="overview-img blink">
                    <a href={data.siteurl}>
                      <img src={`_assets/covers/${newClass}-cover.jpg`} alt={t(data.title)} />
                      {/*<p>{t(data.title)}</p>*/}
                      <p className="price">{t('From')} <AnimatedCounter from={0} to={minPrice} delay={0.3} /> kr</p>
                    </a>
                </div>;

    return (
      !value.noAnim ? (
          <>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 100 },
                visible: { opacity: 1, y: 0 }
              }}
              initial="hidden"
              animate="visible"
              className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-2"
              transition={{ duration: 0.5, delay: delay }}
            >
              {template}
            </motion.div>
          </>
        ) : (
          <>
            {template}
          </>
        )
    );
}

export { FrontImgProductWrapper };