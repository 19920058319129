// import { useIsFetching } from 'react-query';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route, topbar } from 'react-router-loading';
import LoadingScreen from './_components/LoadingScreen';
import Layout from './_pages/Layout';
import Home from './_pages/Home';
import GameServers from './_pages/GameServers';
import GameServer from './_pages/GameServer';
// import Support from './_pages/Support';
// import ViewSupport from './_pages/ViewSupport';
// import ViewSupports from './_pages/ViewSupports';
// import CreateSupport from './_pages/CreateSupport';
import Login from './_pages/Login';
import Profile from './_pages/Profile';
import Status from './_pages/Status';
import NoPage from './_pages/NoPage';
import Payments from './_pages/Payments';
import Policy from './_pages/Policy';
import Settings from './_pages/Settings';
import Terms from './_pages/Terms';
import RePay from './_pages/RePay';
// import FAQ from './_pages/FAQ';
import Wiki from './_pages/Wiki';
// import Forgot from './_pages/Forgot';
// import Reset from './_pages/Reset';
// import Activate from './_pages/Activate';
import Charge from './_pages/Charge';
import Overview from './_pages/Overview';
import AdminOverview from './_pages/AdminOverview';
import ROLES from './_helpers/Roles';
import ReactGA from 'react-ga';
import Unauthorized from './_pages/Unauthorized';
import RequireAuth from './_components/RequireAuth';
import PersistLogin from './_components/PersistLogin';

import 'bootstrap/dist/css/bootstrap.min.css';
import axios from './_api/axios';
import './App.css';

topbar.config({
  autoRun: false,
  barThickness: 2,
  barColors: {
    0: 'rgba(255, 50, 50, .8)',
    0.3: 'rgba(255, 235, 86, .8)',
    1.0: 'rgba(0, 155, 73, .8)',
  },
  shadowBlur: 5,
  shadowColor: 'rgba(0, 0, 0, .8)',
  className: 'topbar',
});

export default function App() {
  const GA_TRACKING_ID = 'G-C7MNFF6VLT';

  ReactGA.initialize(GA_TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // Fetching CSRF Token and adding it to the axios global header
  useEffect(() => {
    let isMounted = true;

    const loadCSRFToken = async (url) => {
      try {
        if(isMounted) {
          // Ensures that requests always is send with cookies
          axios.defaults.withCredentials = true;

            // Requesting a CSRF Token
            const response = await axios.post(url);

              if(response.data.CSRFToken) {
                // Attaching CSRF Token to the axios request header
                // so that it is send with every request made to the API
                axios.defaults.headers.post['X-CSRF-Token'] = response.data.CSRFToken;
              }
        }
      }
      catch(err) {
        if(isMounted) {
          if(!err?.response) {
            console.error(!err);
          }
        }
      }
      finally {
        if(['localhost', '127.0.0.1', ''].includes(window.location.hostname)) {
          console.log('CSRF Token:', axios.defaults.headers.post['X-CSRF-Token']);
        }
        isMounted = false;
      }
    }

      loadCSRFToken(`${process.env.REACT_APP_BACKEND_URL}/csrf/generate`);

    const cleanUp = () => {
        isMounted = false;
    }

      return cleanUp;
  }, []);

  return (
    <BrowserRouter>
      <Routes
        loadingScreen={LoadingScreen}
        maxLoadingTime={300}
      >

        <Route path="/" element={<Layout />} loading>
          {/* Public Routes */}
          <Route path="login" element={<Login />} loading />
          <Route path="login/:activated" element={<Login />} loading />
          <Route path="register" element={<Login />} loading />
          {/*<Route path="forgot" element={<Forgot />} loading />*/}
          {/*<Route path="activate/:token" element={<Activate />} loading />*/}
          {/*<Route path="reset/:token" element={<Reset />} loading />*/}

          {/* Protected Public Routes | @ROLES: User */}
          <Route element={<PersistLogin />}>
            {/* Removes this for now, bescuse we don't need to pwprotect the whole site atm. */}
             {/* <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}> */}
              <Route index element={<Home />} loading />
              <Route path="overview" element={<Overview />} loading />
              <Route path="game-servers" element={<GameServers />} loading />
              <Route path="game-server/:serverName" element={<GameServers />} loading />
              <Route path="game-server/:serverName/:package" element={<GameServer />} loading />
              <Route path="status" element={<Status />} loading />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="privacy-policy" element={<Policy />} loading />
              <Route path="terms-of-service" element={<Terms />} loading />
              {/* <Route path="faq" element={<FAQ />} loading /> */}
              {/* <Route path="wiki" element={<Wiki />} loading /> */}
              {/* <Route path="wiki/:serverName" element={<Wiki />} loading /> */}
              {/* <Route path="wiki/:serverName/:wikiTopic" element={<Wiki />} loading /> */}
              {/* <Route path="wiki/:serverName/:wikiTopic/:wikiPost" element={<Wiki />} loading /> */}
             {/* </Route> */}
          </Route>

          {/* Protected Private Routes | @ROLES: User */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="payments" element={<Payments />} loading />
              <Route path="profile" element={<Profile />} loading />
              <Route path="settings" element={<Settings />} loading />
              {/* <Route path="support" element={<Support />} loading /> */}
              {/* <Route path="support/create" element={<CreateSupport />} loading />*/}
              {/* <Route path="support/view" element={<ViewSupports />} loading />*/}
              {/* <Route path="support/view/:ticket" element={<ViewSupport />} loading />*/}
              <Route path="charge" element={<Charge />} loading />
              <Route path="pay/:server/:product/:productid" element={<RePay />} loading />
            </Route>
          </Route>

          {/* Protected Private Admin Routes | @ROLES: Admin */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="ghost" element={<AdminOverview />} loading />
            </Route>
          </Route>

          {/* 404 - Not Found */}
          <Route element={<PersistLogin />}>
            <Route path="404" element={<NoPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>

        </Route>

      </Routes>
    </BrowserRouter>
  );
}
