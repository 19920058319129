// import { useLoadingContext } from 'react-router-loading';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';
import { useTranslation } from "react-i18next";

import Header from '../_components/Header';
import Footer from '../_components/Footer';

const Terms = () => {
  // const loadingContext = useLoadingContext();
  const { t } = useTranslation();

  const legalName = process.env.REACT_APP_LEGAL_NAME;
  const supportMail = process.env.REACT_APP_SUPPORT_EMAIL;

  // loadingContext.done();

  return (
    <>
      <Header />
        <div id="header_static" className="default-bg container-fluid product single top-bg">
          <div className="product_top d-none d-lg-block">
            <h1>{t("TermsOfService").toUpperCase()}</h1>
          </div>

        <section>
          <MDBContainer className="py-4 small">
            <MDBRow>
              <MDBCol>
                <MDBBreadcrumb className="content-nav bg-dark p-3 mb-4">
                  <MDBBreadcrumbItem>
                    <a href='/'>{t("Home")}</a>
                  </MDBBreadcrumbItem>
                  <MDBBreadcrumbItem active>{t("TermsOfService")}</MDBBreadcrumbItem>
                </MDBBreadcrumb>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg="3">
                <MDBCard className="mb-4 mb-lg-0 sticky-top sticky-offset">
                  <MDBCardBody className="p-0" id="scrollspy">
                    <MDBListGroup>
                      <MDBListGroupItem>
                        <a href="#term-1" className="d-flex justify-content-between align-items-center">
                          1
                          <MDBCardText>{t("Terms1Title")}</MDBCardText>
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="#term-2" className="d-flex justify-content-between align-items-center">
                          2
                          <MDBCardText>{t("Terms2Title")}</MDBCardText>
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="#term-3" className="d-flex justify-content-between align-items-center">
                          3
                          <MDBCardText>{t("Terms3Title")}</MDBCardText>
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="#term-4" className="d-flex justify-content-between align-items-center">
                          4
                          <MDBCardText>{t("Terms4Title")}</MDBCardText>
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="#term-5" className="d-flex justify-content-between align-items-center">
                          5
                          <MDBCardText>{t("Terms5Title", { name: legalName })}</MDBCardText>
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="#term-6" className="d-flex justify-content-between align-items-center">
                          6
                          <MDBCardText>{t("Terms6Title")}</MDBCardText>
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="#term-7" className="d-flex justify-content-between align-items-center">
                          7
                          <MDBCardText>{t("Terms7Title")}</MDBCardText>
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="#term-8" className="d-flex justify-content-between align-items-center">
                          8
                          <MDBCardText>{t("Terms8Title")}</MDBCardText>
                        </a>
                      </MDBListGroupItem>
                    </MDBListGroup>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              <MDBCol lg="9">
                <MDBCard className="mb-4">
                  <MDBCardBody
                    data-mdb-spy="scroll"
                    data-mdb-target="#scrollspy"
                    data-mdb-offset="0"
                    >
                    <MDBRow>
                      <MDBCol sm="12">

                          <h3 id="term-1">1. {t("Terms1Title")}</h3>
                          <ol>
                            <li>{t("Terms1Ordering1")}</li>
                            <li>{t("Terms1Ordering2")}</li>
                            <li>{t("Terms1Ordering3")}</li>
                            <li>{t("Terms1Ordering4")}</li>
                            <li>{t("Terms1Ordering5", { name: legalName })}</li>
                          </ol>
                          <br/>

                          <h3 id="term-2">2. {t("Terms2Title")}</h3>
                          <ol>
                            <li>{t("Terms2Gameserver1", { name: legalName })}</li>
                            <li>{t("Terms2Gameserver2", { name: legalName })}</li>
                            <li>{t("Terms2Gameserver3")}</li>
                            <li>{t("Terms2Gameserver4", { name: legalName })}</li>
                          </ol>
                          <br/>

                          <h3 id="term-3">3. {t("Terms3Title")}</h3>
                          <ol>
                            <li>{t("Terms3Support1", { mail: supportMail })}</li>
                            <li>{t("Terms3Support2", { name: legalName })}</li>
                            <li>{t("Terms3Support3", { name: legalName })}</li>
                            <li>{t("Terms3Support4", { name: legalName })}</li>
                          </ol>
                          <br/>

                          <h3 id="term-4">4. {t("Terms4Title")}</h3>
                          <ol>
                            <li>{t("Terms4Refounds1", { name: legalName })}</li>
                            <li>{t("Terms4Refounds2", { name: legalName })}</li>
                            <li>{t("Terms4Refounds3", { name: legalName })}</li>
                            <li>{t("Terms4Refounds4", { name: legalName })}</li>
                            <li>{t("Terms4Refounds5")}</li>
                            <li>{t("Terms4Refounds6")}</li>
                            <li>{t("Terms4Refounds7", { name: legalName })}</li>
                            <li>{t("Terms4Refounds8", { name: legalName })}</li>
                            <li>{t("Terms4Refounds9", { name: legalName })}</li>
                          </ol>
                          <br/>

                          <h3 id="term-5">5. {t("Terms5Title", { name: legalName })}</h3>
                          <ol>
                            <li>{t("Terms5OtherObligations1", { name: legalName })}</li>
                            <li>{t("Terms5OtherObligations2", { name: legalName })}</li>
                            <li>{t("Terms5OtherObligations3", { name: legalName })}</li>
                          </ol>
                          <br/>

                          <h3 id="term-6">6. {t("Terms6Title")}</h3>
                          <ol>
                            <li>{t("Terms6ForceOf1", { name: legalName })}</li>
                          </ol>
                          <br/>

                          <h3 id="term-7">7. {t("Terms7Title")}</h3>
                          <ol>
                            <li>{t("Terms7Credits1", { name: legalName })}</li>
                            <li>{t("Terms7Credits2")}</li>
                            <li>{t("Terms7Credits3")}</li>
                            <li>{t("Terms7Credits4", { name: legalName })}</li>
                            <li>{t("Terms7Credits5")}</li>
                          </ol>
                          <br/>

                          <h3 id="term-8">8. {t("Terms8Title")}</h3>
                          <ol>
                            <li>{t("Terms8Changes1", { name: legalName })}</li>
                            <li>{t("Terms8Changes2", { name: legalName })}</li>
                          </ol>

                      </MDBCol>
                    </MDBRow>

                  </MDBCardBody>
                </MDBCard>

              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
        
        </div>

      <Footer />
    </>
  )
};

export default Terms;