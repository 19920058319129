import { useEffect, useState, useRef } from 'react';
import { useLoadingContext } from 'react-router-loading';
import { useParams, useNavigate } from 'react-router-dom';

import { axiosPrivate } from '../_api/axios';
import * as Icon from 'react-bootstrap-icons';
import Header from '../_components/Header';
import Footer from '../_components/Footer';
import { useTranslation } from 'react-i18next';

import useAuth from "../_hooks/useAuth";

import ErrorHandler from '../_helpers/ErrorHandler';

import { ProductWrapper } from '../_helpers/ProductWrapper';
import ReceiptLoader from '../_helpers/ReceiptLoader';

import {
  MDBCol,
  MDBRow,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';

const RePay = () => {
  const loadingContext = useLoadingContext();

  const { auth } = useAuth();

  // Check if logged in
  const hasAuth = auth?.roles && auth?.accessToken;

  const reciptRef = useRef(null);
  const params = useParams();
  const { t } = useTranslation();

  const [subscription, setSubscription] = useState(false);

  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedData, setLoadedData] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [credit, setCredit] = useState(0);
  const [canBuy, setCanBuy] = useState(false);

  const [hasAgreed, setHasAgreed] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);

  const [choosenServer, setChoosenServer] = useState({});
  const [choosenServerPackage, setChoosenServerPackage] = useState({});
  const [gameEggIndex, setGameEggIndex] = useState(0);
  const [gameType, setGameType] = useState(0);

  const [customStats, setCustomStats] = useState({});
  const [customPrices, setCustomPrices] = useState({});

  const navigate = useNavigate();

  const handleBuySubmit = async (e) => {
    e.preventDefault();
    setErrMsg('');

    setBtnLoading(true);

    if(!hasAgreed) {
      setErrMsg(t("AcceptOurAgreement"));
      setBtnLoading(true);
      return;
    }

    const sid = params?.server;
    const pid = choosenServer._id;

      try {
        const response = await axiosPrivate.post('/server/renew',
            JSON.stringify({ pid, sid, subscription, choosenServerPackage, customStats, gameType }),
            {
              headers: { 
                'Content-type': 'application/json',
                'Authorization': `Bearer ${auth?.accessToken}`,
              },
              withCredentials: true
            }
        );

        if (response?.status === 200) {
          navigate('/profile', { replace: true });
        }

        if (response?.status === 410) {
          setErrMsg(t("ThisProductIsSoldOut"));
        }

        setBtnLoading(true);

      } catch(err) {
        if (err.response?.status === 409 ||
            err.response?.status === 403 ||
            err.response?.status === 401) {
          setErrMsg(err);
        } else if(err.response?.status === 422) {
          setErrMsg(t("ThereWasAnError"));
        } else if (err.response?.status === 409) {
          setErrMsg(t("NotEnoughCredit"));
        } else if (err.response?.status === 410) {
          setErrMsg(t("ThisProductIsSoldOut"));
        } else {
          setErrMsg(t("PaymentFailed"));
        }
        
        setBtnLoading(false);
      }
  }

  const toggleSubscription = (e) => {
    setSubscription(e.target.checked);
  }

  const toggleAgreed = (e) => {
    setHasAgreed(e.target.checked);
  }

  useEffect(() => {

    let isMounted = true;
      const getProduct = async (url) => {
        setIsLoading(true);
        setErrMsg('');

          try {
            const pid  = params?.product;
            const paid = params?.productid;
            const sid  = params?.server;

              const response = await axiosPrivate.post(url,
                  JSON.stringify({ sid, pid, paid }),
                  {
                    headers: { 
                      'Content-type': 'application/json',
                      'Authorization': `Bearer ${auth?.accessToken}`,
                    },
                    withCredentials: true
                  }
              );

              if (isMounted && !loadedData) {
                  setSubscription(response.data.server.subscription);
                  setChoosenServer(response.data.product);
                  setChoosenServerPackage(response.data.package);
                  setCredit(parseInt(auth?.credit));
                  setGameEggIndex(response.data.server.gtindex);

                  // Add custom adjustment prices from the backend
                  setCustomPrices({ 
                    memory: { price: response.data.prices.memory.price },
                    cpu:    { price: response.data.prices.cpu.price    },
                    disk:   { price: response.data.prices.disk.price   }
                  });

                    if(response.data.product.gametypes) {
                      setGameType(response.data.product.gametypes[0].egg);
                    } else {
                      setErrMsg(t("InvalidEntry"));
                    }

                    if((auth?.credit - response.data.package.price) < 0) {
                      setCanBuy(false);
                    } else {
                      setCanBuy(true);
                    }

                    // Add custom adjustment prices from the backend
                    if(!customStats.memory && !customStats.cpu && !customStats.disk) {

                      let serverCustoms = response.data.server.customs;

                        // Setting index to 0 because there is
                        // only one type stored in the DB at atm.
                        setCustomStats({ 
                          memory: {
                            price: serverCustoms.memory.price,
                            options: [serverCustoms.memory.type],
                            index: 0,
                          },
                          cpu: {
                            price: serverCustoms.cpu.price,
                            options: [serverCustoms.cpu.type],
                            index: 0,
                          },
                          disk: {
                            price: serverCustoms.disk.price,
                            options: [serverCustoms.disk.type],
                            index: 0,
                          }
                        });
                    }

                  setLoadedData(true);
                  setFetchError(null);
              }

              loadingContext.done();
          } catch(err) {
              if (isMounted) {
                // Stopped loading but no product found
                // Redirect the user to our 404 error page
                if (!loadedData) {
                  navigate('/404', { replace: true });
                }
                // console.log(err);
              }
          } finally {
              setIsLoading(false);
              isMounted = false;
          }
      }

      getProduct(`${process.env.REACT_APP_BACKEND_URL}/server/getrenew`);

      const cleanUp = () => {
          isMounted = false;
      }

        return cleanUp;
  }, [fetchError, auth, params, choosenServer, choosenServerPackage, credit, loadedData, t, customStats, navigate, loadingContext]);

  return (
    <>
      <Header />
        <div id="header_static" className={`${choosenServer.headerclass} container-fluid product single top-bg`}>
          <div className="product_top d-none d-lg-block">
            <h1 className={`card-header ${choosenServer.iconclass}`}>
              { choosenServerPackage.titlelang !== undefined ? choosenServer.title : '' }
            </h1>
          </div>

          <div className="container small pull-down">
            <div className="row">
              <div className="product">

                <MDBRow>
                  <MDBCol>
                    <MDBBreadcrumb className="content-nav bg-dark p-3 mb-0">
                      <MDBBreadcrumbItem>
                        <a href='/'>{t("Home")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem>
                        <a href="/game-servers">{t("GameServers")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem>
                        <a href={choosenServer.siteurl}>{choosenServer.title}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem active>{choosenServerPackage.titlelang ? t(choosenServerPackage.titlelang) : '?'}</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="product-cards">
                  <MDBCol xl={!loadedData ? 12 : 5} lg={!loadedData ? 12 : 5} sm={12} xs={12}>

                    { // Loading UI if still in loading state and no data is loaded
                      isLoading && !loadedData &&
                        <div className="card card-body mt-3">
                          <div className="loader-dynamic h-100 d-flex align-items-center justify-content-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                            </div>
                            <p className="mt-3 ms-2">{t('Loading')}</p>
                          </div>
                        </div>
                    }

                    {
                      !isLoading && loadedData &&
                        <ProductWrapper
                          value={choosenServerPackage}
                          noAnim={true}
                          noBtn={true}
                          noPrice={true}
                          noBadge={true}
                          hasAuth={hasAuth}
                          reciptRef={reciptRef}
                          customPrices={customPrices}
                          customBtnsEnabled={true}
                          customStats={customStats}
                          setCustomStats={setCustomStats}
                          video={choosenServer.video}
                          key={choosenServer._id}
                          className="sticky-top sticky-offset mb-4"
                          id="scrollspy"
                        />
                    }
                  </MDBCol>
                  { 
                    !isLoading && loadedData && 
                      <MDBCol xl={7} lg={7} sm={12} xs={12} className="mt-4 mb-4" ref={reciptRef}>

                        <ErrorHandler error={errMsg} />

                        <div
                          className="card card-body product d-flex"
                          data-mdb-spy="scroll"
                          data-mdb-target="#scrollspy"
                          data-mdb-offset="0"
                        >

                          <ReceiptLoader
                            choosenServerPackage={choosenServerPackage}
                            choosenServer={choosenServer}
                            gameEggIndex={gameEggIndex}
                            customStats={customStats}
                            credit={credit}
                            canBuy={canBuy}
                            customPrices={customPrices}
                            autoRenew={subscription}
                            setCanBuy={setCanBuy}
                            hasAuth={hasAuth}
                            reciptRef={reciptRef}
                            hideServersLeft={true}
                          />

                          <div className="d-inline-block align-items-center mt-1">

                            <div className="header-line">
                              <h5 className="card-header p-2">
                                {t("Configuration")}
                              </h5>
                            </div>

                            <p className="alert alert-default d-flex justify-content-center align-items-center mt-3">
                              <Icon.ExclamationCircleFill className="me-3 fs-2 text-warning"/>
                              Her kan du ændre serverens indstillinger inden du fornyer den igen.<br/>Indstillingerne træder først i kraft når betalingen er gennemført!
                            </p>
                              <hr className="mb-3" />

                            <div className="text-center">
                              <input
                                className="form-check-input me-3 mt-2"
                                type="checkbox"
                                onChange={toggleSubscription}
                                checked={subscription}
                                id="subscriptionCheck"
                              />

                              <label className="form-check-label mb-2" htmlFor="subscriptionCheck">
                                {t("MonthlySubscription")}
                              </label>

                               <hr className="mb-3" />
                              <input
                                className="form-check-input me-3 mt-3"
                                type="checkbox"
                                onChange={toggleAgreed}
                                id="registerCheck"
                                aria-describedby="registerCheckHelpText"
                              />

                              <label className="form-check-label mb-2" htmlFor="registerCheck">
                                {t("AgreeTermsStart")} <a href="/terms-of-service">{t("TermsOfService").toLowerCase()}.</a><br /> {t("AgreeTermsEnd")} <a href="/privacy-policy">{t("PrivacyPolicy").toLowerCase()}.</a>
                              </label>

                            </div>

                            <button
                              type="button"
                              disabled={!canBuy || !loadedData || !hasAgreed || btnLoading}
                              className="btn btn-success w-100"
                              onClick={handleBuySubmit}
                            >
                              {t("RenewServer")}
                            </button>

                          </div>

                        </div>
                      </MDBCol>
                  }
                </MDBRow>

                </div>

              </div>
            </div>
          </div>
      <Footer />
    </>
  )
};

export default RePay;