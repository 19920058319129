// import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { motion } from 'framer-motion';
import * as Icon from 'react-bootstrap-icons';

const CommingSoon = () => {
    // const { t } = useTranslation();
  const [showField, setShowField] = useState(false);

  const showPreviewField = () => {
    setShowField(showField ? false : true);
  }

  const onChangeKeyField = (e) => {
    // Just a quick dummie protection
    // backend is disabled anyway.
    if (e.target.value === 'Fjams') {
      window.localStorage.setItem('noCommingSoon', true);
      window.location.reload();
    }
  }

      return (
        <>
          <div className="container d-block align-items-center micro">
            <img src={`${process.env.REACT_APP_BASE_URL}/logo.png`} className="clogo w-50" alt="logo" />
            <p className="alert alert-default d-flex justify-content-around mt-3 alert-top">
              <Icon.HourglassSplit className="ms-2 mt-1 me-3 fs-1 text-warning hopOutAndRotate" onClick={showPreviewField} />
                Bare lige lidt endnu, så er vi klar!<br/> Platformen er desværre stadigvæk under opbygning.
                <a href="https://discord.gg/CzdF799GRn" className="btn btn-purple ms-4 hopUp">
                  <Icon.Discord className="fs-5 text-white"/>
                </a>
            </p>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: -50 },
                visible: { opacity: 1, y: 0 }
              }}
              initial="hidden"
              animate={showField ? 'visible' : 'hidden'}
              transition={{ duration: 0.3, delay: 0 }}
            > 
              <input 
                type="text"
                name="iGotAccess"
                className="form-control"
                placeholder="Indtast din preview key her"
                onChange={onChangeKeyField}
              />
            </motion.div>
          </div>
        </>
      );
};

export default CommingSoon;