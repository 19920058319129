import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLoadingContext } from 'react-router-loading';
import Header from '../_components/Header';
import Footer from '../_components/Footer';
import axios from '../_api/axios';
import { useTranslation } from 'react-i18next';
import { FrontImgProductWrapper } from '../_helpers/FrontImgProductWrapper';
import ErrorHandler from '../_helpers/ErrorHandler';

import {
  MDBCol,
  MDBRow,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';

const Overview = () => {
  const params = useParams();
  const { t } = useTranslation();
  const loadingContext = useLoadingContext();
  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [allPackages, setAllPackages] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [currentProducts, setCurrentProducts] = useState([]);

  // Getting all products
  useEffect(() => {
    let isMounted = true;
      const getProducts = async (url) => {
        setIsLoading(true);
          try {
            const response = await axios.post(url, {
               headers: {
                  'Content-type': 'application/json',
               }
            });

              if (isMounted) {
                let tmpPackages = [];
                  // Looping through all products
                  setAllProducts(response.data.products);
                  response.data.products.map((productValue, index) => {
                      return tmpPackages.push(productValue);
                  })

                  setCurrentProducts(tmpPackages);

                  setFetchError(null);
                  setLoadedOnce(true);
              }

              loadingContext.done();
          } catch(err) {
              if (isMounted) {
                  setFetchError(err.message);
                  setAllPackages([]);
                  setAllProducts([]);
                  setCurrentProducts([]);
                  setLoadedOnce(false);
              }
          } finally {
              setIsLoading(false);
              isMounted = false;
          }
      }

      if(!loadedOnce) {
        getProducts(`${process.env.REACT_APP_BACKEND_URL}/products`);
      }

      const cleanUp = () => {
          isMounted = false;
      }

        return cleanUp;
  }, [fetchError, loadingContext, allPackages, allProducts, params, loadedOnce]);
  
  return (
    <>
      <Header />
        <div id="header_static" className={`wiki-bg container-fluid product top-bg`}>
          <div className="product_top d-none d-lg-block">
            <h1>{t("GameServers").toUpperCase()}</h1>
          </div>

          <div className="container pull-down">

            <div className="row">
              <div className="products">
                <MDBRow>
                  <MDBCol>
                    <MDBBreadcrumb className="content-nav bg-dark p-3 mb-4">

                      <MDBCol className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <MDBBreadcrumbItem>
                          <a href='/'>{t("Home")}</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>{t('GameServers')}</MDBBreadcrumbItem>
                      </MDBCol>
                    
                    </MDBBreadcrumb>
                    <ErrorHandler error={fetchError} />
                  </MDBCol>
                </MDBRow>

              </div>
            </div>

            <div className="row">
              <div className="products cards front_packages_preview w-100">
                {
                  isLoading && (
                    <>
                      <div className="loader-dynamic">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>
                    </>
                  )
                }
                { 
                  !isLoading &&
                  currentProducts.length !== 0 &&
                  currentProducts.map((packageData, index) => {
                    return <FrontImgProductWrapper value={packageData} key={packageData._id} delay={(index / 10)} />
                  })
                }
              </div>
            </div>
          </div>

        </div>
      <Footer />
    </>
  )
};

export default Overview;