import React, { useEffect, useState } from 'react';
import { useLoadingContext } from 'react-router-loading';
import { useTranslation } from 'react-i18next';
import * as Icon from 'react-bootstrap-icons';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';

import Header from '../_components/Header';
import Footer from '../_components/Footer';

import { axiosPrivate } from '../_api/axios';

import UserMenu from '../_components/UserMenu';

import useAuth from '../_hooks/useAuth';

import { PASSWORD_REGEX } from '../_helpers/Utils';

import ErrorHandler from '../_helpers/ErrorHandler';

const Settings = () => {
  const loadingContext = useLoadingContext();
  const { t } = useTranslation();
  const { auth } = useAuth();

  const [discord, setDiscord] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState(null);
  const [success, setSuccess] = useState(false);

  const [settingsData, setSettingsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      let isMounted = true;
        const getSettings = async (url) => {
          setIsLoading(true);
            try {
              const response = await axiosPrivate.get(url, {
                 headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${auth?.accessToken}`,
                 },
                 withCredentials: true
              });

                if (isMounted) {
                  setSettingsData(response.data);

                    if(response.data.discord !== null || response.data.discord !== '') {
                      setDiscord(response.data.discord);
                    }
                }

                loadingContext.done();
            } catch(err) {

                if (isMounted) {
                    setErrMsg(err);
                    setSettingsData([]);
                }
            } finally {
                setIsLoading(false);
                isMounted = false;
            }
        }

        getSettings(`${process.env.REACT_APP_BACKEND_URL}/user/settings`);

        const cleanUp = () => {
            isMounted = false;
        }

        return cleanUp;
    }, [errMsg, auth?.accessToken, t, loadingContext]);

  useEffect(() => {
      setErrMsg('');

      setValidPassword(PASSWORD_REGEX.test(password));
      setValidMatch(password === password2);

  }, [password, password2])

  const updateSettings = (e) => {
    e.preventDefault();
      setSuccess(false);
      setIsLoading(true);

        // If the user is trying to update their password
        if(password !== '' || password2 !== '') {
          // if button enabled with JS hack
          const v1 = PASSWORD_REGEX.test(password);

          if (!v1 || password !== password2) {
              setErrMsg(t("InvalidEntry"));
              return;
          }
        }

        const saveSettings = async (url) => {
          try {

            const response = await axiosPrivate.post(url, 
              JSON.stringify({ discord, password }),
                {
                  headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${auth?.accessToken}`,
                },
                withCredentials: true
            });

              if(response.status === 200) {
                setSettingsData(response.data);
                setPassword('');
                setPassword2('');
                setSuccess(true);
              }

          } catch(err) {
            setErrMsg(err);
            setIsLoading(false);
          } finally {

          }
        }

          saveSettings(`${process.env.REACT_APP_BACKEND_URL}/user/settings`);
  }

  return (
    <>
      <Header />
        <div id="header_static" className="default-bg container-fluid product single top-bg">
          <div className="product_top d-none d-lg-block">
            <h1>{t("Settings").toUpperCase()}</h1>
          </div>

        <section>
          <MDBContainer className="py-4">
            <MDBRow>
              <MDBCol>
                <MDBBreadcrumb className="content-nav bg-dark p-3 mb-4 breadcrumb danger">
                  <MDBBreadcrumbItem>
                    <a href='/'>{t("Home")}</a>
                  </MDBBreadcrumbItem>
                  <MDBBreadcrumbItem active>
                    {t("Settings")}
                  </MDBBreadcrumbItem>
                </MDBBreadcrumb>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg="3">
                <UserMenu />
              </MDBCol>

              <MDBCol lg="9">
                <MDBCard className="mb-4">
                  <MDBCardBody>

                   <form onSubmit={updateSettings}>
                    <MDBRow>
                      <MDBCol className="mt-3" sm="12">
                        <ErrorHandler error={errMsg} />

                        <p className={success && !errMsg ? "alert alert-success" : "offscreen"} aria-live="assertive">
                          <Icon.Check className="me-2 fs-2" />{t("SettingsUpdated")}
                        </p>
                        <h5 className={!isLoading && settingsData && settingsData.discordValidated ? 'mb-3' : 'mb-2'}>{t("DiscordValidation")}</h5>
                          { 
                            !isLoading &&
                            settingsData &&
                            settingsData.discord == null &&
                            !settingsData.discordValidated 
                              ? 
                                <>
                                  <p className="mb-1 text-danger">{t("OnlyChangeIfYouNeed")}!</p>
                                  <p className="mb-1">{t("NeedsToBeExactUsername")}</p>
                                </>
                              : '' 
                          }
                          { 
                            !isLoading &&
                            settingsData &&
                            !errMsg &&
                            settingsData.discord !== null &&
                            !settingsData.discordValidated 
                              ? <p className="mb-1 text-success">{t("PendingDiscordVerification")}!</p> 
                              : '' 
                          }
                          <div className="form-outline">
                            <input
                              type="text"
                              id="discordvalid"
                              className={`form-control ${!isLoading &&
                                                         settingsData &&
                                                         settingsData.discordValidated &&
                                                         settingsData.discord !== null 
                                                          ? 'validated' 
                                                          : '' }
                                        `}
                              autoComplete="off"
                              onChange={(e) => setDiscord(e.target.value)}
                              value={discord !== null ? discord : ''}
                              placeholder={t("DiscordValidatePlaceholder")}
                              disabled={!isLoading && settingsData && settingsData.discordValidated ? true : false}
                            />
                            <label className="form-label w-100" htmlFor="discordvalid">
                              {t("Username")}
                              {
                                !isLoading &&
                                settingsData &&
                                settingsData.discordValidated 
                                  ? <p className="pull-right text-success">{t("Validated")}!</p> 
                                  : ''
                              }
                            </label>
                          </div>

                        <hr className="mb-3" />

                        <h5>{t("ChangeYourPassword")}</h5>
                        <p className="mb-1 text-danger">{t("OnlyChangeIfYouNeed")}!</p>
                        <div className="form-outline mb-2">
                          <input
                              type="password"
                              id="password"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                              className="form-control"
                              aria-invalid={validPassword ? "false" : "true"}
                              aria-describedby="passwordnote"
                              onFocus={() => setPasswordFocus(true)}
                              onBlur={() => setPasswordFocus(false)}
                          />
                          <label className="form-label" htmlFor="registerPassword">
                            {t("Password")}
                            <Icon.Check className={validPassword ? "valid" : "hide"} />
                            <Icon.X className={validPassword || !password ? "hide" : "invalid"} />
                          </label>
                          <p id="passwordnote" className={`${passwordFocus && !validPassword ? "instructions" : "offscreen"} text-white`}>
                              <Icon.InfoCircleFill className="me-2" />
                              {t("PasswordNote1")}.<br />
                              {t("PasswordNote2")}.<br />
                              {t("PasswordNote3")}: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                          </p>
                        </div>

                        <div className="form-outline mb-2">
                          <input
                              type="password"
                              id="confirm_pwd"
                              onChange={(e) => setPassword2(e.target.value)}
                              value={password2}
                              className="form-control"
                              aria-invalid={validMatch ? "false" : "true"}
                              aria-describedby="confirmnote"
                              onFocus={() => setMatchFocus(true)}
                              onBlur={() => setMatchFocus(false)}
                          />
                          <label
                            className="form-label"
                            htmlFor="registerRepeatPassword"
                          >
                            {t("RepeatPassword")}
                            <Icon.Check className={validMatch && password2 ? "valid" : "hide"} />
                            <Icon.X className={validMatch || !password2 ? "hide" : "invalid"} />
                          </label>

                          <p id="confirmnote" className={`${matchFocus && !validMatch ? "instructions" : "offscreen"} text-white`}>
                              <Icon.InfoCircleFill className="me-2" />
                              {t("RepeatPasswordNote")}.
                          </p>
                        </div>

                        <hr className="mb-3" />

                        <button
                          type="submit"
                          className="btn btn-success btn-block mb-4 w-100"
                          disabled={isLoading}
                        >
                          {t("UpdateSettings")}
                        </button>
                      </MDBCol>
                    </MDBRow>
                   </form>


                  </MDBCardBody>
                </MDBCard>

              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        </div>

      <Footer />
    </>
  )
};

export default Settings;