// import { useTranslation } from 'react-i18next';
// import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { passwordSuggestion } from '../_helpers/Utils';

// Looping thorugh all ptero egg variables
// and creates a input field for every one
// with their argument name as the inputs name
const EggLoader = ({ className, gameTypes, eggVars }) => {
    // const { t } = useTranslation();

    const changeInputValue = (e, envVar) => {
      // Changing tmp field current_value,
      // so when the user submits the form
      // these values will follow creating
      // the server.
      eggVars[envVar] = e.target.value;
    }

      return (
        <>
         <form id="eggVars" method="post">
            <ul>
              { 
                gameTypes.length !== 0 && 
                gameTypes.map((eggValue, index) => {
                  // Show if users are allowed to view AND edit
                  if (
                      eggValue.user_viewable === true &&
                      eggValue.user_editable === true
                    ) {

                    // Storing egg.json variables
                    const eggVariable = eggValue.env_variable;

                    // Use the placeholder for the input field
                    // if not anything else is entered
                    let fieldSugg = eggValue.default_value;

                      /**
                       * Special changes to egg.json env vars
                       * goes here, if the server needs some
                       * special thing created around a env
                       * var on setup, here is the place to
                       * write them for the client to see.
                       */


                      // If egg var is filled with this text
                      // it random generates a suggestion password
                      // for the user. So if they don't choose any
                      // it will defaults to that password so that
                      // we increase security in our servers.
                      if(eggValue.default_value === 'CHANGEME') {

                        // Use the new created password for this input field
                        fieldSugg = passwordSuggestion(10, false);

                        // Updating the array of egg vars, to match
                        // the suggested password creation we gave the user.
                        gameTypes[index].current_value = fieldSugg;
                      }

                      // Updating input field with the
                      // placeholder vars as default.
                      // Only if the field not exist!
                      if(!eggVars[eggVariable]) {
                        eggVars[eggVariable] = fieldSugg;
                      }

                      return (
                          <li key={`key-${index}`} className="mb-2">
                            <input type="text"
                              placeholder={fieldSugg}
                              onChange={(event) => changeInputValue(event, eggVariable)}
                              id={`field-${eggVariable}`}
                              className="form-control"
                              name={eggVariable}
                            />
                            <label className="form-label" htmlFor={`field-${eggVariable}`}>{eggValue.name}</label> 
                            <div className="text-white tooltip">
                              <Icon.QuestionCircleFill />
                                <div className="right danger">
                                  {eggValue.description}
                                </div>
                            </div>
                          </li>
                      )
                  } else {
                    return false;
                  }
                })
              }
            </ul>
          </form>
        </>
      );
};

export default EggLoader;