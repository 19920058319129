import { useEffect, useState, useRef } from 'react';
import { useLoadingContext } from 'react-router-loading';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';

import axios, { axiosPrivate } from '../_api/axios';
import Header from '../_components/Header';
import Footer from '../_components/Footer';
import { useTranslation } from 'react-i18next';

import useAuth from '../_hooks/useAuth';

import Select from 'react-select';
import * as Icon from 'react-bootstrap-icons';
import SelectStyle from '../_helpers/SelectStyle';

import ErrorHandler from '../_helpers/ErrorHandler';

import EggLoader from '../_helpers/EggLoader';
import ReceiptLoader from '../_helpers/ReceiptLoader';

import { ProductWrapper, StockColor } from '../_helpers/ProductWrapper';
import { makeUniqueArray } from '../_helpers/Utils';

import {
  MDBCol,
  MDBRow,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';

const BUY_URL = '/buy/server';
const BOUGHT_URL = '/profile';

const GameServers = () => {

  const loadingContext = useLoadingContext();
  const { auth } = useAuth();

  // Check if logged in
  const hasAuth = auth?.roles && auth?.accessToken;

  const reciptRef = useRef(null);
  const params = useParams();
  const { t } = useTranslation();

  const [subscription, setSubscription] = useState(false);

  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedData, setLoadedData] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [badgeClass, setBadgeClass] = useState('green');

  const [credit, setCredit] = useState(0);
  const [stock, setStock] = useState(0);
  const [canBuy, setCanBuy] = useState(false);

  const [products, setProducts] = useState([]);

  const [hasAgreed, setHasAgreed] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);

  const [eggVars] = useState({});
  const [choosenServer, setChoosenServer] = useState({});
  const [choosenServerPackage, setChoosenServerPackage] = useState({});
  const [gameEggIndex, setGameEggIndex] = useState(0);
  const [gameType, setGameType] = useState(0);
  const [gameTypes, setGameTypes] = useState([]);
  const [gameTypeData, setGameTypeData] = useState([]);
  const [panelName, setPanelName] = useState('');

  const [customStats, setCustomStats] = useState({});
  const [customPrices, setCustomPrices] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const handleBuySubmit = async (e) => {
    e.preventDefault();
    setErrMsg('');

    setBtnLoading(true);

    if(!hasAgreed) {
      setErrMsg(t("AcceptOurAgreement"));
      setBtnLoading(true);
      return;
    }

    const packageID = choosenServer._id;

      try {
        const response = await axiosPrivate.post(BUY_URL,
          JSON.stringify({ packageID, panelName, gameType, gameEggIndex, subscription, eggVars, choosenServerPackage, customStats }),
          {
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${auth?.accessToken}`,
            },
            withCredentials: true
          }
        );

        if (response?.status === 200) {
          navigate(BOUGHT_URL, { replace: true });
        }

        if (response?.status === 410) {
          setErrMsg(t("ThisProductIsSoldOut"));
        }

        setBtnLoading(false);

      } catch(err) {
        if (err.response?.status === 409 ||
            err.response?.status === 403 ||
            err.response?.status === 401) {
          setErrMsg(err);
        } else if(err.response?.status === 422) {
          setErrMsg(t("ThereWasAnError"));
        } else if (err.response?.status === 409) {
          setErrMsg(t("NotEnoughCredit"));
        } else if (err.response?.status === 410) {
          setErrMsg(t("ThisProductIsSoldOut"));
        } else {
          setErrMsg(t("PaymentFailed"));
        }

        // Scroll to top
        window.scrollTo(0, 0);
        
        setBtnLoading(false);
      }
  }

  // Toggling monthly subscription
  const toggleSubscription = (e) => {
    setSubscription(e.target.checked);
  }

  // Toggling terms aggreement
  const toggleAgreed = (e) => {
    setHasAgreed(e.target.checked);
  }

  // Changing game type and egg
  const onGameTypeChange = (e) => {
    setGameTypeData(e.data);
    setGameType(e.value);
    setGameEggIndex(e.index);
  }

  // On changing panel name field
  const onChangePanelName = (e) => {
    setPanelName(e.target.value);
  }

  useEffect(() => {

    let isMounted = true;
      const getProduct = async (url) => {
        setIsLoading(true);
        setErrMsg('');

          try {
            const response = await axios.post(url, {
               headers: {
                  'Content-type': 'application/json',
               }
            });

              if (isMounted) {
                // Add custom adjustment prices from the backend
                setCustomPrices({ 
                  memory: { price: response.data.customs.memory.price },
                  cpu:    { price: response.data.customs.cpu.price    },
                  disk:   { price: response.data.customs.disk.price   }
                });

                  setProducts(response.data);
                  setFetchError(null);
                  setCredit(auth?.credit);
              }
          } catch(err) {
              if (isMounted) {
                  // Stopped loading but no product found
                  // Redirect the user to our 404 error page
                  if (!loadedData) {
                    navigate('/404', { replace: true });
                  }
                  setFetchError(err.message);
                  setProducts([]);
              }
          } finally {
              setIsLoading(false);
              isMounted = false;
          }
      }

      getProduct(`${process.env.REACT_APP_BACKEND_URL}/product/` + params.serverName);

      const cleanUp = () => {
          isMounted = false;
      }

        return cleanUp;
  }, [fetchError, auth, navigate, params, loadedData]);

  useEffect(() => {

    if(Object.keys(products).length !== 0 && !isLoading) {

      setChoosenServer(products);

        // Setting default game
        // type options
        if(products.gametypes) {
          setGameType(products.gametypes[0].egg);
          setGameEggIndex(0);
          setGameTypeData(products.gametypes[0].options);
        } else {
          setErrMsg(t("InvalidEntry"));
        }

        let tmpGameTypes = [];

          // Fetching available game types
          products.gametypes.map((typeValue, typeIndex) => {
            return tmpGameTypes.push({ value: typeValue.egg, label: typeValue.name, data: typeValue.options, index: typeIndex });
          });

            setGameTypes(tmpGameTypes);

        let packStartIndex     = 0,
            currentMemoryRange = [],
            currentCpuRange    = [],
            currentDiskRange   = [];

          // Looping through choosen product packages
          products.packages.map((packValue, packIndex) => {
            // Adding current available utillity
            // options for the client to custom.
            // Only add to array if above package
            // is in stock. This ensures no overloading.
            if (packValue.stock !== 0) {
              currentMemoryRange.push(packValue.ram);
              currentCpuRange.push(packValue.cpu);
              currentDiskRange.push(packValue.disk);
            }

              // Found the choosen product package
              if(/[^/]*$/.exec(packValue.link)[0] === params.package) {

                // Stores package index
                packStartIndex = packIndex;
                  
                  setChoosenServerPackage(packValue);
                  setStock(packValue.stock);
                  setLoadedData(true);
                  // Setting stock badge class
                  setBadgeClass(StockColor(packValue.stock));
              }

                  return true;
          });

            // If no custom tmp stats, create one.
            // Keep it down here because inside the loop,
            // we don't get all packages correctly!!
            if(!customStats.memory && !customStats.cpu && !customStats.disk) {

              // Makes the available number of utilization to choose from.
              // Fx. 1, 2, 4, 8, 10 GB RAM packages is available, but if the
              // client is viewing the 4GB package.
              // Then the client will only have 4, 8 & 10 to add as an extra upgrade.
              // This is added from all the packages from the choosen product.
              // Starting from the one, the client is viewing.
              setCustomStats({
                memory: { 
                  options: makeUniqueArray(
                    currentMemoryRange.slice(packStartIndex, currentMemoryRange.length)
                  ),
                  price: 0,
                  index: 0
                },
                cpu: { 
                  options: makeUniqueArray(
                    currentCpuRange.slice(packStartIndex, currentCpuRange.length)
                  ),
                  price: 0,
                  index: 0
                },
                disk: { 
                  options: makeUniqueArray(
                    currentDiskRange.slice(packStartIndex, currentDiskRange.length)
                  ),
                  price: 0,
                  index: 0
                }
              });
            }

            // Setting preview panel name if
            // the client not choose any
            setPanelName(`Min ${choosenServer.title} Server`);

            // First stop loading
            // When all is done!
            loadingContext.done();
    }

  }, [choosenServerPackage, params, products, choosenServer, customStats, isLoading, t, loadingContext]);

  return (
    <>
      <Header />

        <div id="header_static" className={`${choosenServer.headerclass ? choosenServer.headerclass : 'wiki-bg'} container-fluid product single top-bg`}>
          <div className="product_top d-none d-lg-block">
            <h1 className={`card-header ${!isLoading && choosenServer.iconclass}`}>
              { !isLoading && choosenServerPackage.titlelang !== undefined ? choosenServer.title : '' }
            </h1>
          </div>

          <div className="container small pull-down">
            <div className="row">
              <div className="product">

                <MDBRow>
                  <MDBCol>
                    <MDBBreadcrumb className="content-nav bg-dark p-3 mb-0">
                      <MDBBreadcrumbItem>
                        <a href='/'>{t("Home")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem>
                        <a href="/overview">{t("GameServers")}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem>
                        <a href={choosenServer.siteurl}>{choosenServer.title ? choosenServer.title : '...'}</a>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem active>{!isLoading && choosenServerPackage.titlelang ? t(choosenServerPackage.titlelang) : '...'}</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="product-cards">
                  <MDBCol xl={!loadedData ? 12 : 5} lg={!loadedData ? 12 : 5} sm={12} xs={12}>

                    { // Loading UI if still in loading state and no data is loaded
                      isLoading && !loadedData &&
                        <div className="card card-body mt-3">
                          <div className="loader-dynamic h-100 d-flex align-items-center justify-content-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                            </div>
                            <p className="mt-3 ms-2">{t('Loading')}</p>
                          </div>
                        </div>
                    }

                    {
                      !isLoading && loadedData &&
                        <ProductWrapper 
                          value={choosenServerPackage}
                          noAnim={true}
                          noBtn={true}
                          noPrice={true}
                          noBadge={true}
                          hasAuth={hasAuth}
                          reciptRef={reciptRef}
                          customPrices={customPrices}
                          customBtnsEnabled={true}
                          customStats={customStats}
                          setCustomStats={setCustomStats}
                          video={choosenServer.video}
                          key={choosenServer._id}
                          className="sticky-top sticky-offset mb-4"
                          id="scrollspy"
                        />
                    }
                  </MDBCol>
                  { 
                    !isLoading && loadedData && 
                      <MDBCol xl={7} lg={7} sm={12} xs={12} className="mt-4 mb-4" ref={reciptRef}>

                        <ErrorHandler error={errMsg} />

                        { 
                          hasAuth && stock !== 0 ? (
                            <>
                              <div 
                                className="card card-body product d-flex"
                                data-mdb-spy="scroll"
                                data-mdb-target="#scrollspy"
                                data-mdb-offset="0"
                              >
                                {
                                  choosenServerPackage.discount !== 0 &&
                                    <div className="ribbon-wrapper">
                                      <div className="ribbon">{choosenServerPackage.discount}% {t("DiscountMsg")}</div>
                                    </div>
                                }

                                <ReceiptLoader 
                                  choosenServerPackage={choosenServerPackage}
                                  choosenServer={choosenServer}
                                  gameEggIndex={gameEggIndex}
                                  customStats={customStats}
                                  credit={credit}
                                  canBuy={canBuy}
                                  autoRenew={subscription}
                                  setCanBuy={setCanBuy}
                                  hasAuth={hasAuth}
                                  badgeClass={badgeClass}
                                  reciptRef={reciptRef}
                                />

                                <div className="d-inline-block align-items-center mt-1">

                                  {
                                    // Load game types if any
                                    choosenServer.gametypes &&
                                    choosenServer.gametypes.length > 1 &&
                                      <>
                                        <div className="header-line">
                                          <h5 className="card-header p-2">
                                            {t("ChooseServerType")}
                                          </h5>
                                        </div>
                                        <Select
                                          options={gameTypes}
                                          styles={SelectStyle}
                                          id="choosetmp"
                                          placeholder={choosenServer.gametypes[0].name}
                                          className="countryChooser"
                                          onChange={onGameTypeChange}
                                        />
                                        <label className="form-label" htmlFor="choosetmp">
                                          {t("GameType")}
                                        </label>
                                      </>
                                  }

                                <div className="header-line">
                                  <h5 className="card-header p-2">
                                    {t("ServerSettings")}
                                  </h5>
                                </div>

                                  <p className="alert alert-default d-flex justify-content-center align-items-center mt-3">
                                    <Icon.ExclamationCircleFill className="me-3 fs-2 text-warning"/>
                                    Konfigurer din server her under så den lever op til lige præcis dine krav!<br/>
                                    Denne opsætning vil altid kunne ændres senere.
                                  </p>

                                  <input
                                    type="text"
                                    id="panel-name"
                                    onChange={onChangePanelName}
                                    placeholder={`Min ${choosenServer.title} Server`}
                                    className="form-control"
                                  />
                                  <label className="form-label" htmlFor="panel-name">
                                    {t("PanelName")}
                                  </label>
                                  <div className="text-white tooltip">
                                    <Icon.QuestionCircleFill />
                                      <div className="right danger">
                                        Navnet serveren vil blive vist med i admin panelet her hos os. Bruges så du kan kende forskel på dine servere.
                                      </div>
                                  </div>

                                  <EggLoader gameTypes={gameTypeData} eggVars={eggVars} />

                                  <p className="alert alert-default d-flex justify-content-between align-items-center mt-3">
                                    <Icon.QuestionSquare className="ms-2 me-3 fs-1 text-warning"/>
                                      Problemer med køb eller opsætning af din server ?<br/> Så kan du finde hjælp i vores wiki eller på discord.
                                      <a href="https://discord.gg/CzdF799GRn" className="btn btn-purple ms-4 hopUp">
                                        <Icon.Discord className="fs-5 text-white"/>
                                      </a>
                                  </p>

                                  <MDBCol xl={12} lg={12} sm={12} xs={12} className="text-center">
                                    <hr className="mb-3" />
                                    <input
                                      className="form-check-input me-3 mt-1"
                                      type="checkbox"
                                      onChange={toggleSubscription}
                                      id="subscriptionCheck"
                                    />

                                    <label className="form-check-label mb-2" htmlFor="subscriptionCheck">
                                      {t("MonthlySubscription")}
                                    </label>
                                    
                                    <p>{t("DeployedIn")} <i className="text-success">{choosenServerPackage ? choosenServerPackage.deployment : 0}s</i></p>
                                     <hr className="mb-3" />
                                    <input
                                      className="form-check-input me-3 mt-3"
                                      type="checkbox"
                                      onChange={toggleAgreed}
                                      id="registerCheck"
                                      aria-describedby="registerCheckHelpText"
                                    />

                                    <label className="form-check-label mb-2" htmlFor="registerCheck">
                                      {t("AgreeTermsStart")}&nbsp;
                                      <a href="/terms-of-service">{t("TermsOfService").toLowerCase()}.</a>
                                      <br /> 
                                      {t("AgreeTermsEnd")}
                                      <a href="/privacy-policy">{t("PrivacyPolicy").toLowerCase()}.</a>
                                    </label>

                                    <button
                                      type="button"
                                      disabled={!canBuy || !loadedData || !hasAgreed || stock === 0 ? true : false || btnLoading}
                                      className="btn btn-success w-100"
                                      onClick={handleBuySubmit}
                                    >
                                      <Icon.Check className="fs-4 me-2" />{t("BuyServer")}
                                    </button>
                                  </MDBCol>

                                </div>
                              </div>
                            </>
                            // Not logged in 
                        ) : (
                          <>
                            <div className="card card-body product d-flex">
                              {
                                loadedData && choosenServerPackage.discount !== 0 &&
                                  <div className="ribbon-wrapper">
                                    <div className="ribbon">{choosenServerPackage.discount}% {t("DiscountMsg")}</div>
                                  </div>
                              }

                              { 
                                loadedData && !isLoading &&
                                  <ReceiptLoader 
                                    choosenServerPackage={choosenServerPackage}
                                    choosenServer={choosenServer}
                                    gameEggIndex={gameEggIndex}
                                    customStats={customStats}
                                    credit={credit}
                                    canBuy={canBuy}
                                    autoRenew={subscription}
                                    setCanBuy={setCanBuy}
                                    hasAuth={hasAuth}
                                    badgeClass={badgeClass}
                                  />
                              }

                              <div className="header-line mt-4">
                                <h5 className="card-header p-2">
                                  {t("ServerSettings")}
                                </h5>
                              </div>
                              <p className="alert alert-default d-flex justify-content-between align-items-center mt-3">
                                <Icon.ExclamationSquare className="ms-2 me-3 fs-1 text-warning"/>
                                  Du skal være logget ind for at kunne købe en server!<br/>
                                  Hvis du ikke har en konto, kan du nemt oprette én her.
                                  <Link 
                                    to={`${process.env.REACT_APP_BASE_URL}/register`}
                                    state={{ from: { pathname : location.pathname } }}
                                    className="btn btn-success ms-4 hopUp const"
                                  >
                                    <Icon.KeyFill className="fs-3 text-white"/>
                                  </Link>
                              </p>

                            </div>
                          </>
                        )
                      }

                      </MDBCol>
                    }

                </MDBRow>

              </div>
            </div>
          </div>

        </div>

      <Footer />
    </>
  )
};

export default GameServers;