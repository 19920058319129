import React, { useState, useEffect, useRef } from 'react';
import { animate, useInView, useAnimation } from 'framer-motion'

// from={} to={} delay={} duration={}

const AnimatedCounter = (props) => {
  const from = props.from;
  const to = props.to;
  const delay = props.delay;
  const duration = (props.duration ? props.duration : 0.5);
  const [counter, setCounter] = useState(0);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if(isInView) {
      animate(from, to, {
        delay: delay,
        duration: duration,
        ease: 'easeOut',
        onUpdate(value) {
          setCounter(Math.round(value));
        }
      })
    }
  }, [isInView, mainControls, from, to, delay, duration]);

  return (
    <span ref={ref}>
      {counter}
    </span>
  );
};
export default AnimatedCounter;