/**
 * ---------------------------------------------------
 * Available Registration Countries
 * ---------------------------------------------------
 * These are the allowed countries for registrants 
 * to use, other than them, we cannot promise,
 * a stable enough ping to play without lags.
 * ---------------------------------------------------
 */
const AvailableCountries = [
    { value: 'DK', label: 'Danmark', timezone: '+1', currency: 'DKK' },
    { value: 'NO', label: 'Norge', timezone: '+1', currency: 'NOK' },
    { value: 'SWE', label: 'Sverige', timezone: '+1', currency: 'SEK' },
    { value: 'DE', label: 'Tyskland', timezone: '+1', currency: 'EURO' },
    { value: 'FI', label: 'Finland', timezone: '+2', currency: 'EURO' },
]

export default AvailableCountries;