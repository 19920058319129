import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import * as Icon from 'react-bootstrap-icons';
// import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import '../_translations/i18n';

// import axios from '../_api/axios';

const Footer = () => {

  const { t } = useTranslation();
  // const [backendStatus, setBackendStatus] = useState('online');

  // const [ showMenu, setShowMenu ] = useState('hide');

  // Open menu function
  // const openMenu = async (e) => {
  //   e.preventDefault();
  //     setShowMenu(showMenu === 'hide' ? 'show' : 'hide');
  // };

  // const ping = () => {

  //     let img = new Image();

  //       try {
  //         img.onload = function() {
  //           setBackendStatus('running');
  //           console.log('API Online!');
  //         }

  //         img.src = `${process.env.REACT_APP_BACKEND_URL}/favicon.ico`;
  //       } catch(err) {
  //           setBackendStatus('offline');
  //           console.log('API Offline!');
  //       }
  // }

  // useEffect(() => {
  //   ping();
  // }, []);

  return (
    <MDBFooter bgColor='dark' className='text-center text-lg-start text-muted mt-4' id="footer">
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span data-for="site-info" data-tip={`Site ${true ? 'Online' : 'Offline'}`}>
            <Icon.CircleFill className={`icon ${true} mt-2`}/>
          </span>
        </div>

        <div className="me-4 text-reset">
          <a href='https://discord.gg/CzdF799GRn' className='text-reset' data-for="discord" data-tip={t("JoinOurDiscord")}>
            <button className="btn btn-primary m-1 discord">
              <Icon.Discord />
            </button>
          </a>

          {/*<MDBNavbarItem className="dropdown-wrapper d-inline-block">

            <div className="dropdown">
              <a href="#!" onClick={openMenu} data-bs-target="#nav" data-bs-toggle="dropdown" className="text-reset" data-tip={t("SiteSettings")}>
                <button className="btn btn-primary m-1 settings">
                  <Icon.GearFill />
                </button>
              </a>

              <div className={`fade-down dropdown-menu ${showMenu}`} id="nav">
                <ul>
                  <li>
                    <Icon.Activity className="me-1 green" /> Latency
                  </li>
                  <li>
                    <Icon.ArrowLeftRight className="me-1 green" /> Ping
                  </li>
                </ul>
              </div>
            </div>

          </MDBNavbarItem>*/}

        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <Icon.Diagram3Fill className="me-2" />
                {process.env.REACT_APP_COMPAN_NAME}
              </h6>
              <p>
                {t('SiteDescription')}
              </p>
            </MDBCol>

            <MDBCol md="3" lg="4" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>{t('UsefulLinks')}</h6>
              <p>
                <Icon.TagFill className="me-2" />
                <a href='/terms-of-service' className='text-reset'>
                  {t('TermsOfService')}
                </a>
              </p>
              <p>
                <Icon.Sunglasses className="me-2" />
                <a href='/privacy-policy' className='text-reset'>
                  {t('PrivacyPolicy')}
                </a>
              </p>
              {/*<p>
                <Icon.QuestionDiamondFill className="me-2" />
                <a href='/faq' className='text-reset'>
                  {t('FAQ')}
                </a>
              </p>*/}
              <p>
                <Icon.Wikipedia className="me-2" />
                <a href='https://guides.gamborghost.dk' className='text-reset'>
                  {t('Guides')}
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="4" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>{t('ClientArea')}</h6>
              <p>
                <Icon.HeartPulseFill className="me-2" />
                <a href='/status' className='text-reset'>
                  {t('ServerStatus')}
                </a>
              </p>
              <p>
                <Icon.HddRackFill className="me-2" />
                <a href='/overview' className='text-reset'>
                  {t('GameServers')}
                </a>
              </p>
              <p>
                <Icon.Discord className="me-2" />
                <a href="https://discord.gg/CzdF799GRn" className='text-reset'>
                  {t('Discord')} 
                </a>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', borderTop: '1px #1a1a1a solid' }}>
        <a className='text-reset fw-bold m-1' href={process.env.REACT_APP_BASE_URL}>
          {process.env.REACT_APP_COMPAN_NAME}
        </a>
        © 2022 - {new Date().getFullYear()}
      </div>
    </MDBFooter>
  );
};
export default Footer;