import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import * as Icon from 'react-bootstrap-icons';
import AnimatedCounter from '../_helpers/AnimatedCounter';

// import * as Icon from 'react-bootstrap-icons';

// Generating a client receipt, so that
// they can see what they are about to buy
const ReceiptLoader = ({
      className,
      choosenServerPackage,
      choosenServer,
      customStats,
      gameEggIndex,
      credit = 0,
      canBuy,
      setCanBuy,
      autoRenew,
      hasAuth,
      badgeClass,
      hideServersLeft = false }) => {
    const { t } = useTranslation();
    const [creditLeft, setCreditLeft] = useState(0);
    const [price, setPrice] = useState(0);
    const [cpuPrice, setCpuPrice] = useState(0);
    const [diskPrice, setDiskPrice] = useState(0);
    const [memoryPrice, setMemoryPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [lastTotalAmount, setLastTotalAmount] = useState(0);

    const totalHTML = <p className="m-0 text-white">
                        {t('Total')}: <AnimatedCounter from={lastTotalAmount} to={totalAmount} duration={0.4} /> kr
                      </p>

    useEffect(() => {
      // Last price amount, used for animating
      setLastTotalAmount(choosenServerPackage.price);

      // Product raw price
      setPrice(choosenServerPackage.price);

      // Custom addon prices
      setCpuPrice(customStats && customStats.cpu.price ? customStats.cpu.price : 0);
      setMemoryPrice(customStats && customStats.memory.price ? customStats.memory.price : 0);
      setDiskPrice(customStats && customStats.disk.price ? customStats.disk.price : 0);

      // Calculating the discount if any
      if(!hideServersLeft) {
        setDiscount(choosenServerPackage.price - ((choosenServerPackage.discount / 100) * choosenServerPackage.price));
      } else {
        setDiscount(choosenServerPackage.price);
      }

      // Calculating total reciept cost
      setTotalAmount((discount !== 0 ? discount : price) + cpuPrice + diskPrice + memoryPrice);

      // Calculating credit left after purchase
      setCreditLeft(credit - totalAmount);

      // Validates that the user
      // has credit enough to buy
      if ((credit - totalAmount) < 0) {
        setCanBuy(false);
      } else {
        setCanBuy(true);
      }

    }, [choosenServerPackage, choosenServer, customStats, discount, price, cpuPrice, diskPrice, memoryPrice, credit, totalAmount, setCanBuy, hideServersLeft]);

      return (
        <>
          <div className="header-line">
            <h5 className="card-header p-2">
              {t("Invoice")}
            </h5>
          </div>
          <div className="container">

            <div className="row d-flex align-items-center">
              <div className="col-5">
              { // If the server package is out of stock
                choosenServerPackage.stock === 0 ?
                    hasAuth ? // If out of stock and logged in
                      <>
                        {
                          !hideServersLeft &&
                            <p className="mb-1 red">
                              {t("ThisProductIsSoldOut")}!
                            </p>
                        }
                        {t("YourCredit")} -&nbsp;
                        <span className={credit > 0 ? 'text-success' : 'text-danger'}>
                          {t('CurrencyFormat', { currency: credit.toFixed(2) })}
                        </span>
                      </>
                    : // Out of stock without login
                      !hideServersLeft &&
                        <p className="mt-3 red">
                          {t("ThisProductIsSoldOut")}!
                        </p>
                :
                  hasAuth ? // Stock available and logged in
                    <p className="m-0">
                      { 
                        !hideServersLeft &&
                          <>
                            <b className={`${badgeClass}`}>{ choosenServerPackage.stock }</b> -&nbsp;
                            { choosenServerPackage.stock === 1 ? t("StockLeft") : t("StockLeft_Plural") }<br />
                          </>
                      }
                      {t("YourCredit")} -&nbsp;
                      <span className={credit > 0 ? 'text-success' : 'text-danger'}>
                        {t('CurrencyFormat', { currency: credit.toFixed(2) })}
                      </span>
                    </p>
                  : // Stock available and not logged in
                    <>
                      <b className={`${badgeClass}`}>{ choosenServerPackage.stock }</b> -&nbsp;
                      { choosenServerPackage.stock === 1 ? t("StockLeft") : t("StockLeft_Plural") }
                    </>
              }
              </div>
              <div className="col-7 text-right">
                <h5 className="m-0">{choosenServer.title}</h5>
                <small className="d-block mb-2">
                  <i>{t('SharedHosting')}</i>
                </small>
              </div>
              <hr />
            </div>

            <ul className="list-unstyled">
              <li>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    <p className="m-0 text-white">
                      <b>{choosenServer.title}</b>
                      {
                        choosenServer && choosenServer.gametypes && choosenServer.gametypes.length > 1 && 
                          <> -&nbsp;
                            <i>{choosenServer.gametypes[gameEggIndex].name}</i>
                          </>
                      }
                    </p>
                  </div>
                  <div className="col-3">
                    <p className="float-end m-0">
                      { 
                        choosenServerPackage.discount !== 0 && !hideServersLeft && 
                          <>
                            <s>{t('CurrencyFormat', { currency: price })}</s> -&nbsp;
                          </>
                      }
                      <span className="text-white">{t('CurrencyFormat', { currency: discount })}</span>
                    </p>
                  </div>
                  <hr />
                </div>
              </li>

              {/*<li>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    &nbsp;&nbsp;<p className="m-0">⤷ {t('ServerLocation')}: Danmark (København)</p>
                  </div>
                  <div className="col-3">
                    <p className="float-end m-0">{t('CurrencyFormat', { currency: memoryPrice })}</p>
                  </div>
                  <hr className="dark" />
                </div>
              </li>*/}

              <li>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    &nbsp;&nbsp;<p className="m-0">⤷ RAM: {(customStats && customStats.memory.options.length < 0) ? customStats.memory.options[customStats.memory.index] : choosenServerPackage.ram.toFixed(2)} GB (<i>{(memoryPrice === 0) ? t('Included') : t('Extra')}</i>)</p>
                  </div>
                  <div className="col-3">
                    <p className="float-end m-0">{t('CurrencyFormat', { currency: memoryPrice })}</p>
                  </div>
                  <hr className="dark" />
                </div>
              </li>

              <li>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    &nbsp;&nbsp;<p className="m-0">
                      ⤷ CPU Tråde: {(customStats && customStats.cpu.options.length < 0) ? customStats.cpu.options[customStats.cpu.index] : choosenServerPackage.cpu} stk
                      (<i>{(cpuPrice === 0) ? t('Included') : t('Extra')}</i>)
                      </p>
                  </div>
                  <div className="col-3">
                    <p className="float-end m-0">{t('CurrencyFormat', { currency: cpuPrice })}</p>
                  </div>
                  <hr className="dark" />
                </div>
              </li>

              <li>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    &nbsp;&nbsp;<p className="m-0">
                      ⤷ {choosenServerPackage.template === 1 ? 'Tick' : t('DiskSpace') }:&nbsp;
                        {(customStats && customStats.disk.options.length < 0) ? customStats.disk.options[customStats.disk.index] : choosenServerPackage.disk.toFixed(2)}
                        {choosenServerPackage.template === 1 ? '' : ' GB' } (<i>{(diskPrice === 0) ? t('Included') : t('Extra')}</i>)
                    </p>
                  </div>
                  <div className="col-3">
                    <p className="float-end m-0">{t('CurrencyFormat', { currency: diskPrice })}</p>
                  </div>
                  <hr />
                </div>
              </li>

              { 
                autoRenew && 
                  <li>
                    <div className="row d-flex align-items-center">
                      <div className="col-9">
                        &nbsp;&nbsp;<p className="m-0">
                          + Automatisk fornyelse
                        </p>
                      </div>
                      <div className="col-3">
                        <p className="float-end m-0">{t('CurrencyFormat', { currency: 0 })}</p>
                      </div>
                      <hr />
                    </div>
                  </li>
              }

              <li>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    <p className="m-0 text-white"><b>{choosenServerPackage.discount !== 0 ? !hideServersLeft ? t("FirstMonth") : t("PrMonth") : t("PrMonth")}</b></p>
                  </div>
                  <div className="col-3">
                    <p className="float-end m-0 text-white"><b>{t('CurrencyFormat', { currency: totalAmount })}</b></p>
                  </div>
                  <hr />
                </div>
              </li>
            </ul>
            {
              hasAuth ?
                // If we have enough credit, show left after purchase
                totalAmount <= credit ?
                  <div className="text-right">
                    <h5>
                      {totalHTML}
                      {
                        choosenServerPackage.stock !== 0 &&
                          <small className="fs-7">{t('CreditLeft')} -&nbsp;
                            <span className="text-danger">{creditLeft} kr</span>
                          </small>
                      }
                    </h5>
                  </div>
                :
                  <>
                    <div className="text-right">
                      <h5>
                        {totalHTML}
                      </h5>
                    </div>
                    <p className="alert alert-default d-flex justify-content-around mt-3 alert-top">
                      <Icon.ExclamationSquare className="ms-2 mt-1 me-3 fs-1 text-warning" />
                        Du har for lidt penge på din konto!<br/>Men du kan hurtigt få den optanket her
                        <a href={`${process.env.REACT_APP_BASE_URL}/charge`} className="btn btn-success ms-4 hopUp">
                          <Icon.CreditCard2BackFill className="fs-5 text-white"/>
                        </a>
                    </p>
                  </>
                :
                  <div className="text-right">
                    <h5>
                      {totalHTML}
                    </h5>
                  </div>
            }
          </div>
        </>
      );
};

export default ReceiptLoader;