// import { useTranslation } from 'react-i18next';
// import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';

const Maintenance = () => {
    // const { t } = useTranslation();

      return (
        <>
          <div className="container d-block align-items-center micro">
            <img src={`${process.env.REACT_APP_BASE_URL}/logo.png`} className="clogo w-50" alt="logo" />
              <p className="alert alert-default d-flex justify-content-around mt-3">
                <Icon.ExclamationTriangleFill className="ms-2 mt-1 me-3 fs-1 text-warning hopOut"/>
                  Serverne opdatere for at holde alt sikkert!<br/> Vi er tilbage om lidt igen, hav venligst tilmodighed.
                  <a href="https://discord.gg/CzdF799GRn" className="btn btn-purple ms-4 hopUp">
                    <Icon.Discord className="fs-5 text-white"/>
                  </a>
              </p>
          </div>
        </>
      );
};

export default Maintenance;