import React, { useState, useEffect } from 'react';
import { useLoadingContext } from 'react-router-loading';
import SiteLogo from '../_assets/images/logo.png';
// import * as Icon from 'react-bootstrap-icons';
import { bytesToSize } from '../_helpers/Utils';

import { motion } from 'framer-motion'

import GhostLazyBgImage from '../_helpers/GhostLazyBgImage';

// import { useTranslation } from 'react-i18next';
import { axiosPrivate } from '../_api/axios';

import ErrorHandler from '../_helpers/ErrorHandler';

import useAuth from '../_hooks/useAuth';

/** OVERVIEW FUNCTION */
const AdminOverview = () => {
  const loadingContext = useLoadingContext();
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [memoryLeftBar, setMemoryLeftBar] = useState(0);
  const [diskLeftBar, setDiskLeftBar] = useState(0);
  const [totalUsedDisk, setTotalUsedDisk] = useState(0);
  const [totalDisk, setTotalDisk] = useState(0);
  const [diskLeft, setDiskLeft] = useState(0);
  const [totalMemory, setTotalMemory] = useState(0);
  const [totalUsedMemory, setTotalUsedMemory] = useState(0);
  const [memoryLeft, setMemoryLeft] = useState(0);

  // const { t } = useTranslation();
  const { auth } = useAuth();

  const convertBytes = (bytes) => {
    return bytesToSize(bytes*1024*1024, 2);
  }

  const updateCapacityOnClick = (e) => {
    e.preventDefault();
        
        const updateCapacity = async (url) => {
          setIsLoading(true);
            try {
              const response = await axiosPrivate.get(url, {
                 headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${auth?.accessToken}`,
                 },
                 withCredentials: true
              });

                const totalUsedDisk     = response.data.totalUsedDisk;
                const totalDisk         = response.data.totalDisk;
                const diskLeft          = response.data.diskSpaceLeft;
                const totalMemory       = response.data.totalMemory;
                const totalUsedMemory   = response.data.totalUsedMemory;
                const memoryLeft        = response.data.memoryLeft;

                const memoryUsedPercent = ((totalUsedMemory / totalMemory * 100)).toFixed(1);

                // Calculates percentage of capacity on disk is used
                const spaceLeftPercent  = ((totalUsedDisk / totalDisk) * 100).toFixed(1);

                  setTotalUsedDisk(convertBytes(totalUsedDisk));
                  setTotalDisk(convertBytes(totalDisk));
                  setDiskLeft(convertBytes(diskLeft));
                  setTotalMemory(convertBytes(totalMemory));
                  setTotalUsedMemory(convertBytes(totalUsedMemory));
                  setMemoryLeft(convertBytes(memoryLeft));

                  setMemoryLeftBar(memoryUsedPercent);
                  setDiskLeftBar(spaceLeftPercent);
                  setErrors(null);
            } catch(err) {
                setErrors(err.message);
                setTotalUsedDisk(0);
                setTotalDisk(0);
                setDiskLeft(0);
                setTotalMemory(0);
                setTotalUsedMemory(0);
                setMemoryLeft(0);
            } finally {
                setIsLoading(false);
            }
        }

          updateCapacity(`${process.env.REACT_APP_BACKEND_URL}/ghost/capacity/update`);
  }

    useEffect(() => {
      let isMounted = true;

        const getOverviewData = async (url) => {
          setIsLoading(true);
            try {
              const response = await axiosPrivate.get(url, {
                 headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${auth?.accessToken}`,
                 },
                 withCredentials: true
              });

                if (isMounted) {
                    const totalUsedDisk     = response.data.totalUsedDisk;
                    const totalDisk         = response.data.totalDisk;
                    const diskLeft          = response.data.diskSpaceLeft;
                    const totalMemory       = response.data.totalMemory;
                    const totalUsedMemory   = response.data.totalUsedMemory;
                    const memoryLeft        = response.data.memoryLeft;

                    const memoryLeftpercent = ((totalUsedMemory / totalMemory * 100)).toFixed(1);

                    // Calculates percentage of capacity on disk is used
                    const spaceLeftPercent  = ((totalUsedDisk / totalDisk) * 100).toFixed(1);
                      
                      setTotalUsedDisk(convertBytes(totalUsedDisk));
                      setTotalDisk(convertBytes(totalDisk));
                      setDiskLeft(convertBytes(diskLeft));
                      setTotalMemory(convertBytes(totalMemory));
                      setTotalUsedMemory(convertBytes(totalUsedMemory));
                      setMemoryLeft(convertBytes(memoryLeft));

                      setMemoryLeftBar(memoryLeftpercent);
                      setDiskLeftBar(spaceLeftPercent);
                      setErrors(null);
                }

                loadingContext.done();
            } catch(err) {

                if (isMounted) {
                    setErrors(err.message);
                    setTotalUsedDisk(0);
                    setTotalDisk(0);
                    setDiskLeft(0);
                    setTotalMemory(0);
                    setTotalUsedMemory(0);
                    setMemoryLeft(0);
                }
            } finally {
                isMounted && setIsLoading(false);
            }
        }

          getOverviewData(`${process.env.REACT_APP_BACKEND_URL}/ghost`);

        const cleanUp = () => {
            isMounted = false;
        }

        return cleanUp;
    }, [auth?.accessToken, loadingContext]);

  return (
    <>
      <GhostLazyBgImage />
      <div className="pt-5">
        <a href="/" className="d-flex justify-content-center">
          <img src={SiteLogo} alt="contacts" id="login_logo" />
        </a>

        <div className="container col-6 col-xs-12 col-md-12 col-lg-6 col-xl-6">
          <ErrorHandler error={errors} />
        </div>
        <div className="container col-6 col-xs-12 col-md-12 col-lg-6 col-xl-6 mt-4 overview-content">
          <div className={`btn btn-success ${isLoading ? 'disabled' : ''}`} onClick={updateCapacityOnClick}>Update Total Capacity</div>
          { isLoading && <small className="ms-2 fadeInOut">Updating Capacity...</small> }
            <hr className="mt-3 mb-3" />
            <small>Memory ~ {totalUsedMemory} / {totalMemory} ~ {memoryLeft} left</small>
            <motion.div
              variants={{
                hidden: { width: 0 },
                visible: { width: '100%' }
              }}
              initial="hidden"
              animate="visible"
              className="progress"
              transition={{ duration: 1, delay: 0 }}
            > 
                <div 
                  className="progress-bar progress-bar-striped bg-success"
                  role="progressbar"
                  style={{ width: `${memoryLeftBar}%` }}
                  aria-valuenow={memoryLeftBar}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >{memoryLeftBar}%</div>
            </motion.div>

            <small className="mt-2 d-block">Disk Space ~ {totalUsedDisk} / {totalDisk} ~ {diskLeft} left</small>
            <motion.div
              variants={{
                hidden: { width: 0 },
                visible: { width: '100%' }
              }}
              initial="hidden"
              animate="visible"
              className="progress"
              transition={{ duration: 1, delay: 0 }}
            > 
                <div 
                  className="progress-bar progress-bar-striped bg-success"
                  role="progressbar"
                  style={{ width: `${diskLeftBar}%` }}
                  aria-valuenow={diskLeftBar}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >{diskLeftBar}%</div>
            </motion.div>

        </div>

        {/*<div className="container col-6 col-xs-12 col-md-12 col-lg-6 col-xl-6 mt-5 overview-content">
          <div className="text-right">Total Errors: </div>
          <div className="row scrollwindow p500">

          </div>
        </div>*/}

      </div>
    </>
  );
};

export default AdminOverview;
