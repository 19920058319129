import React, { useState, useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import { useLoadingContext } from 'react-router-loading';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';

import Banner from '../_components/banners/Banner';

import GhostLazyImage from '../_helpers/GhostLazyImage';

import Header from '../_components/Header';
import Footer from '../_components/Footer';

import axios from '../_api/axios';

import { useTranslation } from 'react-i18next';
import '../_translations/i18n';

import * as Icon from 'react-bootstrap-icons';

import { minTotalByPrice } from '../_helpers/Utils';

import { ProductWrapper } from '../_helpers/ProductWrapper';
import SpecsBanner from '../_components/banners/SpecsBanner';
import AnimatedCounter from '../_helpers/AnimatedCounter';

import EasyToUseImg from '../_assets/images/EasyToUse.jpg';
import AddYourFriends from '../_assets/images/AddYourFriends.jpg';
import FileBrowserImg from '../_assets/images/FileBrowser.jpg';
import SliderLoaderPreview from '../_assets/images/SliderPreview.jpg';

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  800: { items: 2 },
  1000: { items: 3 },
  1400: { items: 4 },
  // 1800: { items: 5 },
};

const Home = () => {
  const loadingContext = useLoadingContext();
  const { t } = useTranslation();
  const [currentProduct, setCurrentProduct] = useState('');

  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [choosenServer, setChoosenServer] = useState([]);
  const [packageItems, setPackageItems] = useState([]);
  const [availableLogos, setAvailableLogos] = useState([]);
  const [currentProductTitle, setCurrentProductTitle] = useState('');
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalStats, setTotalStats] = useState([]);
  const [items, setItems] = useState([]);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if(isInView) {
      mainControls.start('visible');
      slideControls.start('visible');
    }
  }, [isInView, mainControls, slideControls]);

  useEffect(() => {
    let isMounted = true;
      const getProducts = async (url) => {
        setIsLoading(true);

          try {
            const response = await axios.post(url, {
              headers: {
                'Content-type': 'application/json',
              }
            });

              if (isMounted) {
                setChoosenServer(response.data.products);
                setCurrentProduct(response.data.products[0]._id);
                setTotalProducts(response.data.products.length);
                setTotalStats(response.data.stats);
                setFetchError(null);

                  // Loading slides in here to avoid it resetting on product change
                  setItems([]);
                  response.data.products.map((value, index) => {  

                      // Product by logo chooser slide
                      setAvailableLogos((packageItems) => 
                        // Mini icons for the package chooser
                        [...packageItems, 
                          <motion.div 
                            variants={{
                              hidden: { opacity: 0, y: -75 },
                              visible: { opacity: 1, y: 0 }
                            }}
                            initial="hidden"
                            animate="visible"
                            transition={{ duration: 0.5, delay: (index / 10) }}
                            className="list-group-item"
                          >
                            <span id={value._id} className={value.iconclass} onDragStart={handleDragStart} onClick={choosePackage}></span>
                          </motion.div>]);

                      // If products if enabled for frontpage view
                      if(value.slidefrontpage === true) {

                        // Frontpage slides
                        const minPrice = minTotalByPrice(value.packages, value.title);
                          return setItems((items) => [...items,
                            <div className={`front_slide vw-100`} onDragStart={handleDragStart}>
                              <GhostLazyImage src={value.slideurl} className="vh-100" />
                                <div className="container">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="slide-text">
                                       <h2>{value.slidetitle}</h2>
                                        <p className="mt-3">
                                          {t('ServersFrom')} <b>{t('CurrencyFormat', { currency: minPrice })}</b> / {t('AMonth')}
                                        </p>
                                         <a href={value.siteurl} className="btn btn-primary">{t('OrderNow')}</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          ]);

                      } else {
                        return false;
                      }
                  });
              }

              // Preventing slideshow from being blank
              // on page load, because of a bug.
              window.dispatchEvent(new Event('resize'));

              // Ending loading screen
              loadingContext.done();

          } catch(err) {
            if (isMounted) {
              setFetchError(err.message);
              setChoosenServer([]);
            }
          } finally {
            // Preventing slideshow from being blank
            // on page load, because of a bug.
            setTimeout(function() {
              window.dispatchEvent(new Event('resize'));
            }, 50);
            isMounted && setIsLoading(false);
          }
      }

      getProducts(`${process.env.REACT_APP_BACKEND_URL}/products/frontpage`);

      const cleanUp = () => {
          isMounted = false;
      }

      return cleanUp;
  }, [fetchError, t, loadingContext]);

  useEffect(() => {

    if(choosenServer.length !== 0 && !isLoading) {
      setPackageItems([]);

      choosenServer.map((value, index) => {
        if(value._id === currentProduct) {
          setCurrentProductTitle(value.title);
            value.packages.map((packValue, packIndex) => {
              return setPackageItems((packageItems) => 
                [...packageItems, <ProductWrapper value={packValue} key={packValue._id} delay={(packIndex / 10)} />]);
              });
        }
          return true;

      })

    }

  }, [t, choosenServer, currentProduct, isLoading]);

  // Choosing a different package
  // on logo carousel click.
  const choosePackage = async (e) => {
    e.preventDefault();
      setCurrentProduct(e.target.id);
  }

  /**
   * Returns a random integer between min (inclusive) and max (inclusive).
   * The value is no lower than min (or the next integer greater than min
   * if min isn't an integer) and no greater than max (or the next integer
   * lower than max if max isn't an integer).
   * Using Math.round() will give you a non-uniform distribution!
   */
  const getRandomInt = (min, max) => {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
      return Math.floor(Math.random() * (maxFloored - minCeiled + 1)) + minCeiled;
  }


  const truncateString = (str, num) => {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
      return str
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...'
  }

  return (
    <>
      <Header />
        <div id="front_carousel" className="container">
         <div className={`front_slide vw-100`}>
            { 
              isLoading ? (
                <div className={`front_slide vw-100`}>
                  <div className="loader-dynamic">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                  <img src={SliderLoaderPreview} alt="Slider preloader" className="w-100" />
                </div>
              ) : (
                <AliceCarousel
                  autoWidth={true}
                  autoPlay={true}
                  infinite={true}
                  syncStateOnPropsUpdate={true}
                  disableDotsControls={false}
                  disableButtonsControls={true}
                  keyboardNavigation={false}
                  autoPlayStrategy="default"
                  controlsStrategy="responsive"
                  autoPlayInterval={9000}
                  animationDuration={1000}
                  animationType="slide"
                  touchTracking={true}
                  mouseTracking={true}
                  items={items}
                  shouldHandleResizeEvent={true}
                />
              )
            }
          </div>
        </div>

        <SpecsBanner className="danger" />

        <div className="container">
          <Row className="mt-4">
            <Col xs={12} sm={12} md={12} lg={5}>
              <h1 className="align-items-center">
                {t('Packages')} <small>- {currentProductTitle}</small>
              </h1>
            </Col>
            <Col xs={12} sm={12} md={12} lg={7} className="d-flex justify-content-end choices mt-2">
              <Col xs={12} sm={12} md={12} lg={7} className="d-flex justify-content-end mini-icons">
                <Col xs={12} sm={12} md={4} lg={4} className="mt-2 text-right">
                  <p className="mt-2">{t('ChooseAPackage')} <Icon.ArrowRight className="me-4" /></p>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} className="mini-icons">
                  <AliceCarousel
                    autoWidth={true}
                    autoPlay={true}
                    infinite={true}
                    autoPlayInterval={0}
                    autoPlayStrategy="default"
                    animationDuration={2000}
                    responsive={false}
                    animationType="slide"
                    touchTracking={true}
                    disableDotsControls={true}
                    disableButtonsControls={true}
                    mouseTracking={true}
                    //paddingRight={10}
                    //paddingLeft={10}
                    items={availableLogos}
                    controlsStrategy="default"
                    // shouldHandleResizeEvent={true}
                    transitionTimingFunction="linear"
                  />
                </Col>
              </Col>
            </Col>
          </Row>

            <div id="package_carousel" className="container products">
            { 
              items.length === 0 ? (
                <>
                  <div className={`front_packages_preview w-100`}>
                    <div className="loader-dynamic">
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <AliceCarousel
                    responsive={responsive}
                    animationType="slide"
                    touchTracking={true}
                    disableDotsControls={true}
                    keyboardNavigation={true}
                    // disableButtonsControls
                    mouseTracking={true}
                    //paddingRight={10}
                    //paddingLeft={10}
                    items={packageItems}
                    controlsStrategy="responsive"
                    // shouldHandleResizeEvent={true}
                  />
                </>
              )
            }
            </div>
        </div>

        <div className="container-fluid mt-3 frontpage_banner">
          <Row id="statics" ref={ref}>
              <motion.div 
                variants={{
                  hidden: { opacity: 0, y: 120 },
                  visible: { opacity: 1, y: 0 }
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.3, delay: 0.20 }}
                className="text-center mt-5 mb-4 col-xs-4 col-sm-4 col-md-4 col-lg-4"
              >
                <Icon.Fingerprint className="circle" />
                <h1><AnimatedCounter from={0} to={totalStats.total} delay={0.5} />+</h1>
                <p className="raleway">{t('HappyCustomers')}</p>
              </motion.div>

              <motion.div 
                variants={{
                  hidden: { opacity: 0, y: 120 },
                  visible: { opacity: 1, y: 0 }
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.3, delay: 0.30 }}
                className="text-center mt-5 mb-4 col-xs-4 col-sm-4 col-md-4 col-lg-4"
              >
                <Icon.CaretRightFill className="circle" />
                <h1><AnimatedCounter from={0} to={totalStats.played} delay={1} />+</h1>
                <p className="raleway">{t('HostedServers')}</p>
              </motion.div>

              <motion.div 
                variants={{
                  hidden: { opacity: 0, y: 120 },
                  visible: { opacity: 1, y: 0 }
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.3, delay: 0.40 }}
                className="text-center mt-5 mb-4 col-xs-4 col-sm-4 col-md-4 col-lg-4"
              >
                <Icon.Joystick className="circle" />
                <h1><AnimatedCounter from={0} to={totalProducts} delay={1.5} />+</h1>
                <p className="raleway">{t('DifferentGames')}</p>
              </motion.div>

          </Row>
        </div>

        <div className="container justify-content-center d-flex mt-5">
          <Row id="fronttabs">
            <h1 className="align-items-center">
              {t('WhyChooseUs')} ?  <small>- {t('SimplyBecause')}</small>
            </h1>
            <Col className="text-center mb-4" xs={12} sm={12} md={12} lg={12}>

              <Tab.Container
                defaultActiveKey="easyToUse"
                id="frontpage"
                className="mb-3"
              >
                <Nav variant="tabs" className="mt-3">
                  <Nav.Link eventKey="easyToUse">
                    <h5 className="text-success">{t('EasyToUse')}</h5>
                      {truncateString(t('EasyToUseText'), 16)}
                  </Nav.Link>
                  <Nav.Link eventKey="getHelp">
                    <h5 className="text-success">{t('AddYourFriends')}</h5>
                      {truncateString(t('AddYourFriendsText'), 18)}
                  </Nav.Link>
                  <Nav.Link eventKey="easyFileManager">
                    <h5 className="text-success">{t('EasyFileManager')}</h5>
                      {truncateString(t('EasyFileManagerText'), 28)}
                  </Nav.Link>
                </Nav>


                <Tab.Content className="mt-4 overflow-hidden">
                  <Tab.Pane eventKey="easyToUse">
                    <Row>

                      <Col xs={12} sm={5} md={5} lg={5} className="text-left" ref={ref}>
                        <motion.div 
                          variants={{
                            hidden: { opacity: 0, y: 120 },
                            visible: { opacity: 1, y: 0 }
                          }}
                          initial="hidden"
                          animate={mainControls}
                          transition={{ duration: 0.5, delay: 0.10 }}
                        >
                          <h3>{t('EasyToUseTitle')}</h3>
                          <p>{t('EasyToUseText')}</p>
                        </motion.div>
                      </Col>

                      <Col xs={12} sm={7} md={7} lg={7} ref={ref} style={{ position: 'relative', minHeight: '450px' }}>
                        <motion.div 
                          variants={{
                            hidden: { opacity: 0, right: '-100%' },
                            visible: { opacity: 1, right: '2%' }
                          }}
                          initial="hidden"
                          animate={slideControls}
                          transition={{ duration: 1, delay: 0.15 }}
                          className="preview-mobile"
                          style={{
                            position: 'relative',
                            top: 4,
                            right: 0,
                            zIndex: 1
                          }}
                        >
                          <img src={EasyToUseImg} alt={t('EasyToUse')} />
                        </motion.div>
                      </Col>

                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="getHelp">
                    <Row>
                      <Col xs={12} sm={5} md={5} lg={5} className="text-left">
                        <h3>{t('AddYourFriendsTitle')}</h3>
                        <p>{t('AddYourFriendsText')}</p>
                      </Col>
                      <Col xs={12} sm={7} md={7} lg={7} className="preview-mobile">
                        <img src={AddYourFriends} alt={t('AddYourFriends')} />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="easyFileManager">
                    <Row>
                      <Col xs={12} sm={5} md={5} lg={5} className="text-left">
                        <h3>{t('EasyFileManagerTitle')}</h3>
                        <p>{t('EasyFileManagerText')}</p>
                      </Col>
                      <Col xs={12} sm={7} md={7} lg={7} className="preview-mobile">
                        <img src={FileBrowserImg} alt={t('EasyFileManager')} />
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </div>

        <div className="container-fluid mb-5 mt-5">
          <div className="row frontpage_banner danger">

          </div>
        </div>

        <div className="container mb-5 mt-3">
          <Banner 
            xs={10}
            sm={10}
            md={10}
            lg={10}
            banner={getRandomInt(1, 3)} />
        </div>

      <Footer />
    </>
  )
};

export default Home;