import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import * as Icon from 'react-bootstrap-icons';
import { doDiscount, strShorten } from './Utils';
import AnimatedCounter from '../_helpers/AnimatedCounter';


/**
 * ---------------------------------------------------
 * Stock color
 * ---------------------------------------------------
 * Shows different colors when reaching certain levels
 * of stock amount.
 * ---------------------------------------------------
 */

const StockColor = (stock) => {
  if(stock <= parseInt(process.env.REACT_APP_MIN_STOCK)) {
    return 'red';
  } else if (stock <= parseInt(process.env.REACT_APP_MEDIUM_STOCK)) {
    return 'yellow';
  } else {
    return 'green';
  }
}


/**
 * ---------------------------------------------------
 * Product cards for every pages
 * ---------------------------------------------------
 * These cards are used over the entire site, to make
 * it more dynamic, so that we only have to change 
 * them in one place.
 * They can show discount, or hide buy btn and tags.
 * Every product have a template, they can load their
 * data into. 
 * This means that they can change the way they look
 * from product to product.
 * ---------------------------------------------------
 */

const ProductWrapper = ({ className,
                          value,
                          product,
                          delay,
                          noAnim,
                          noBtn,
                          noPrice,
                          hasAuth,
                          customPrices,
                          noBadge = false,
                          shorten = false,
                          video = false,
                          customBtnsEnabled = false,
                          customStats,
                          reciptRef,
                          setCustomStats
                        }) => {

  const { t }    = useTranslation();
  const title    = product !== undefined && product.title ? product.title : false;

  let template   = '',
      stock      = parseInt(value.stock),
      discount   = doDiscount(value),
      badgeClass = StockColor(stock);

  const SHORTENLENGTH = 50;

  const packageDiscountRibbon = !noPrice && 
                                  value.discount !== 0 &&
                                    <div className="ribbon-wrapper">
                                      <div className="ribbon">{t("DiscountMsg")} {value.discount}%</div>
                                    </div>

  const packageVideo = video &&
                          <div id="video-overlay">
                            <video id="video-preview" muted={true} loop={true} autoPlay={true}>
                              <source src={`_assets/videos/${video}`} type="video/mp4" />
                            </video>
                          </div>

  const packageStockBadge = !noBadge &&
                              <span className={`badge badge-success ${badgeClass}`}>
                                { value.stock >= 99 ? '99+' : value.stock !== 0 ? value.stock : "!" }
                              </span>

  const packagePrice = !noPrice ?
                          value.discount !== 0 ?
                            <h2 className="price">
                              <AnimatedCounter from={0} to={discount} delay={0.15} /> kr
                              <small> / {t('FirstMonth')}</small>
                            </h2>                  
                          :
                            <h2 className="price">
                              <AnimatedCounter from={0} to={value.price} delay={0.15} /> kr
                              <small> / {t('AMonth')}</small>
                            </h2>
                          :
                            '' // No HTML


  const productTitle = title && <small className="title">- {title}</small>


  // Takes the user to the
  // package when clicked
  const goToPackage = () => {
    if(!video) {
      window.location = process.env.REACT_APP_BASE_URL + value.link;
    }
  }

  // Adding custom adjustment prices from the backend
  const memoryIncreaseCost = (customPrices) ? customPrices.memory.price : 100;
  const cpuIncreaseCost    = (customPrices) ? customPrices.cpu.price    : 150;
  const diskIncreaseCost   = (customPrices) ? customPrices.disk.price   : 95;

  // Increasing custom stats in packages
  const onClickIncrease = (e, type) => {
    switch(type) {
      default:
        break;
      // Ram
      case 0:
        let csMOptions = customStats.memory.options,
            csMPrice   = customStats.memory.price,
            csMIndex   = customStats.memory.index;

          // Adds the changes price to the recipt
          customStats.memory.price = ((csMOptions.length - 1) > csMIndex ? (csMPrice + memoryIncreaseCost) : csMPrice);

          // Ensures that we can't choose beyond the array
          customStats.memory.index = ((csMOptions.length - 1) > csMIndex ? (csMIndex + 1) : csMIndex);
        break;

      // Cpu
      case 1:
        let csCOptions = customStats.cpu.options,
            csCPrice   = customStats.cpu.price,
            csCIndex   = customStats.cpu.index;

          // Adds the changes price to the recipt
          customStats.cpu.price = ((csCOptions.length - 1) > csCIndex ? (csCPrice + cpuIncreaseCost) : csCPrice);

          // Ensures that we can't choose beyond the array
          customStats.cpu.index = ((csCOptions.length - 1) > csCIndex ? (csCIndex + 1) : csCIndex);
        break;

      // Disk
      case 2:
        let csDOptions = customStats.disk.options,
            csDPrice   = customStats.disk.price,
            csDIndex   = customStats.disk.index;

          // Adds the changed price to the recipt
          customStats.disk.price = ((csDOptions.length - 1) > csDIndex ? (csDPrice + diskIncreaseCost) : csDPrice);

          // Ensures that we can't choose beyond the array
          customStats.disk.index = ((csDOptions.length - 1) > csDIndex ? (csDIndex + 1) : csDIndex);
        break;
    }

      // Force re-render to update UI
      setCustomStats({ ...customStats });

        // Scroll to recipt
        if(reciptRef) {
          reciptRef.current.scrollIntoView({
            behavior: 'smooth'
          });
        }
  }

  // Decreasing custom stats in packages
  const onClickDecrease = (e, type) => {
    switch(type) {
      default:
        break;
      // Ram
      case 0:
        let csMPrice = customStats.memory.price,
            csMIndex = customStats.memory.index;

          // Adds the changed price to the recipt
          customStats.memory.price = (csMPrice > 0 ? (csMPrice - memoryIncreaseCost) : 0);

          // Ensures that we can't choose beyond the array
          customStats.memory.index = (csMIndex > 0 ? (csMIndex - 1) : 0);
        break;

      // Cpu
      case 1:
        let csCPrice = customStats.cpu.price,
            csCIndex = customStats.cpu.index;

          // Adds the changed price to the recipt
          customStats.cpu.price = (csCPrice > 0 ? (csCPrice - cpuIncreaseCost) : 0);

          // Ensures that we can't choose beyond the array
          customStats.cpu.index = (csCIndex > 0 ? (csCIndex - 1) : 0);
        break;

      // Disk
      case 2:
        let csDPrice = customStats.disk.price,
            csDIndex = customStats.disk.index;

          // Adds the changed price to the recipt
          customStats.disk.price = (csDPrice > 0 ? (csDPrice - diskIncreaseCost) : 0);

          // Ensures that we can't choose beyond the array
          customStats.disk.index = (csDIndex > 0 ? (csDIndex - 1) : 0);
        break;
    }

      // Force re-render to update UI
      setCustomStats({ ...customStats });

        // Scroll to recipt
        if(reciptRef) {
          reciptRef.current.scrollIntoView({
            behavior: 'smooth'
          });
        }
  }

  switch(String(value.template)) {

    // ---------------------
    // Default Template
    // ---------------------
    default:
      template = 
        <>
        <div onClick={goToPackage} className={`product-card pw card-wrapper ${className !== undefined ? className : ''} ${value.size !== undefined ? `col-${value.size}` : 'col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3'}`}>
          <div className={`card card-body product d-flex flex-column ${video ? 'video' : 'cursor-hand'} ${className ? className : ''}`}>
              { packageVideo }
              { packageDiscountRibbon }
              { packageStockBadge }
           <h3 className={`card-header ${value.iconclass}${video ? ' video-header' : ''}`}>
            {t(value.titlelang)}
            { productTitle }
           </h3>
           { packagePrice }
           <p className={`m-1 ${video ? 'ms-3' : ''}`}>
             {shorten ? strShorten(t(value.descriptionlang), SHORTENLENGTH) : t(value.descriptionlang)}
           </p>
           <ul className={`m-2 ${video ? 'ms-4 mt-3' : ''}`}>
            <li>
              <Icon.PeopleFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('Slots', { slots: value.slots })}</p>
            </li>
            <li className="position-relative">
              <Icon.Memory className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBRAM', { ram: (value.stock !== 0 && customStats && customStats.memory ? customStats.memory.options[customStats.memory.index] : value.ram) })}</p>&nbsp;
            </li>
            <li className="position-relative">
              <Icon.CpuFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('CPUCount', { cpucount: (value.stock !== 0 && customStats && customStats.cpu ? customStats.cpu.options[customStats.cpu.index] : value.cpu), clockspeed: value.cputype })}</p>&nbsp;
            </li>
            <li className="position-relative">
              <Icon.DeviceSsdFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBDisk', { disk: (value.stock !== 0 && customStats && customStats.disk ? customStats.disk.options[customStats.disk.index] : value.disk) })}</p>&nbsp;
            </li>
            {/*<li>
              <Icon.Ethernet className="me-1 fs-5"/>
              <p className="fs-6">{t('GBNetwork', { networkspeed: value.network })}</p>
            </li>*/}
            { 
              process.env.REACT_APP_DDOS_ENABLED === 'true' &&
                <li>
                  <Icon.ShieldLockFill className="me-1 fs-5 text-success"/>
                  <p className="fs-6 text-success">{t('DDoSProtection')}</p>
                </li>
            }
          </ul>
          {
            !noBtn && <a href={value.link} className="btn btn-success btn-lg w-100 mt-auto">{t('OrderNow')}</a>
          }
          </div>
        </div>
        </>
      break;

    // ---------------------
    // Minecraft
    // ---------------------
    case '0':
      template = 
        <>
        <div onClick={goToPackage} className={`product-card pw card-wrapper ${className !== undefined ? className : ''} ${value.size !== undefined ? `col-${value.size}` : 'col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3'}`}>
          <div className={`card card-body product d-flex flex-column ${video ? 'video' : 'cursor-hand'} ${className ? className : ''}`}>
              { packageVideo }
              { packageDiscountRibbon }
              { packageStockBadge }
           <h3 className={`card-header ${value.iconclass}${video ? ' video-header' : ''}`}>
            {t(value.titlelang)}
            { productTitle }
           </h3>
           { packagePrice }
           <p className={`m-1 ${video ? 'ms-3' : ''}`}>
             {shorten ? strShorten(t(value.descriptionlang), SHORTENLENGTH) : t(value.descriptionlang)}
           </p>
           <ul className={`m-2 ${video ? 'ms-4 mt-3' : ''}`}>
            <li>
              <Icon.PeopleFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('Slots', { slots: value.slots })}</p>
            </li>
            <li className="position-relative">
              <Icon.Memory className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBRAM', { ram: (value.stock !== 0 && customStats && customStats.memory ? customStats.memory.options[customStats.memory.index] : value.ram) })}</p>&nbsp;
              { hasAuth && customStats && stock !== 0 && (customStats.memory.options.length > 1) && customStats.memory && 
                <div className="text-white tooltip">
                  <Icon.QuestionCircleFill />
                    <div className="right danger">
                      {t('Options')}: {customStats.memory.options.join(' GB, ')} GB
                    </div>
                </div>
              }
              { customBtnsEnabled && stock !== 0 && (customStats.memory.options.length > 1) && hasAuth &&
                <>
                  <div className="customPackBtnWrapper">
                    <div className={`btn btn-success customPackBtn me-1 ${customStats.memory.index === (customStats.memory.options.length-1) ? 'disabled' : ''}`} onClick={(event) => onClickIncrease(event, 0)}>+</div>
                    <div className={`btn btn-success customPackBtn ${customStats.memory.index === 0 ? 'disabled' : ''}`} onClick={(event) => onClickDecrease(event, 0)}>-</div>
                  </div>
                </>
              }
            </li>
            <li className="position-relative">
              <Icon.CpuFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('CPUCount', { cpucount: (value.stock !== 0 && customStats && customStats.cpu ? customStats.cpu.options[customStats.cpu.index] : value.cpu), clockspeed: value.cputype })}</p>&nbsp;
              { hasAuth && customStats && stock !== 0 && (customStats.cpu.options.length > 1) && customStats.cpu && 
                <div className="text-white tooltip">
                  <Icon.QuestionCircleFill />
                    <div className="right danger">
                      {t('Options')}: {customStats.cpu.options.join(' stk, ')} stk
                    </div>
                </div>
              }
              { customBtnsEnabled && stock !== 0 && (customStats.cpu.options.length > 1) && hasAuth &&
                <>
                  <div className="customPackBtnWrapper">
                    <div className={`btn btn-success customPackBtn me-1 ${customStats.cpu.index === (customStats.cpu.options.length-1) ? 'disabled' : ''}`} onClick={(event) => onClickIncrease(event, 1)}>+</div>
                    <div className={`btn btn-success customPackBtn ${customStats.cpu.index === 0 ? 'disabled' : ''}`} onClick={(event) => onClickDecrease(event, 1)}>-</div>
                  </div>
                </>
              }
            </li>
            <li className="position-relative">
              <Icon.DeviceSsdFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBDisk', { disk: (value.stock !== 0 && customStats && customStats.disk ? customStats.disk.options[customStats.disk.index] : value.disk) })}</p>&nbsp;
              { hasAuth && customStats && stock !== 0 && (customStats.disk.options.length > 1) && customStats.disk && 
                <div className="text-white tooltip">
                  <Icon.QuestionCircleFill />
                    <div className="right danger">
                      {t('Options')}: {customStats.disk.options.join(' GB, ')} GB
                    </div>
                </div>
              }
              { customBtnsEnabled && stock !== 0 && (customStats.disk.options.length > 1) && hasAuth &&
                <>
                  <div className="customPackBtnWrapper">
                    <div className={`btn btn-success customPackBtn me-1 ${customStats.disk.index === (customStats.disk.options.length-1) ? 'disabled' : ''}`} onClick={(event) => onClickIncrease(event, 2)}>+</div>
                    <div className={`btn btn-success customPackBtn ${customStats.disk.index === 0 ? 'disabled' : ''}`} onClick={(event) => onClickDecrease(event, 2)}>-</div>
                  </div>
                </>
              }
            </li>
            {/*<li>
              <Icon.MapFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('LiveMapAvailable')}, <a href="https://bluecolored.de/bluemap/#overworld:-936:54:198:59:-0.17:0.45:0:0:perspective" target="_new">{t('DemoHere')}</a></p>
            </li>*/}
            {/*<li>
              <Icon.Ethernet className="me-1 fs-5"/>
              <p className="fs-6">{t('GBNetwork', { networkspeed: value.network })}</p>
            </li>*/}
            { 
              process.env.REACT_APP_DDOS_ENABLED === 'true' &&
                <li>
                  <Icon.ShieldLockFill className="me-1 fs-5 text-success"/>
                  <p className="fs-6 text-success">{t('DDoSProtection')}</p>
                </li>
            }
          </ul>
          {
            !noBtn && <a href={value.link} className="btn btn-success btn-lg w-100 mt-auto">{t('OrderNow')}</a>
          }
          </div>
        </div>
        </>
        break;


    // ---------------------
    // CS:GO
    // ---------------------
    case '1':
      template =
        <>
        <div onClick={goToPackage} className={`product-card pw card-wrapper ${className !== undefined ? className : ''} ${value.size !== undefined ? `col-${value.size}` : 'col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3'}`}>
          <div className={`card card-body product d-flex flex-column ${video ? 'video' : 'cursor-hand'} ${className ? className : ''}`}>
              { packageVideo }
              { packageDiscountRibbon }
              { packageStockBadge }
           <h3 className={`card-header ${value.iconclass} ${video ? 'video-header' : ''}`}>
              {t(value.titlelang)} 
              { productTitle }
           </h3>
           { packagePrice }
           <p className={`m-1 ${video ? 'ms-3' : ''}`}>
             {shorten ? strShorten(t(value.descriptionlang), SHORTENLENGTH) : t(value.descriptionlang)}
           </p>
           <ul className={`m-2 ${video ? 'ms-4 mt-3' : ''}`}>
            <li>
              <Icon.PeopleFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('Slots', { slots: value.slots })}</p>
            </li>
            <li className="position-relative">
              <Icon.Memory className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBRAM', { ram: (value.stock !== 0 && customStats && customStats.memory ? customStats.memory.options[customStats.memory.index] : value.ram) })}</p>&nbsp;
              { hasAuth && customStats && stock !== 0 && (customStats.memory.options.length > 1) && customStats.memory && 
                <div className="text-white tooltip">
                  <Icon.QuestionCircleFill />
                    <div className="right danger">
                      {t('Options')}: {customStats.memory.options.join(' GB, ')} GB
                    </div>
                </div>
              }
              { customBtnsEnabled && stock !== 0 && (customStats.memory.options.length > 1) && hasAuth &&
                <>
                  <div className="customPackBtnWrapper">
                    <div className={`btn btn-success customPackBtn me-1 ${customStats.memory.index === (customStats.memory.options.length-1) ? 'disabled' : ''}`} onClick={(event) => onClickIncrease(event, 0)}>+</div>
                    <div className={`btn btn-success customPackBtn ${customStats.memory.index === 0 ? 'disabled' : ''}`} onClick={(event) => onClickDecrease(event, 0)}>-</div>
                  </div>
                </>
              }
            </li>
            <li className="position-relative">
              <Icon.CpuFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('CPUCount', { cpucount: (value.stock !== 0 && customStats && customStats.cpu ? customStats.cpu.options[customStats.cpu.index] : value.cpu), clockspeed: value.cputype })}</p>&nbsp;
              { hasAuth && customStats && stock !== 0 && (customStats.cpu.options.length > 1) && customStats.cpu && 
                <div className="text-white tooltip">
                  <Icon.QuestionCircleFill />
                    <div className="right danger">
                      {t('Options')}: {customStats.cpu.options.join(' stk, ')} stk
                    </div>
                </div>
              }
              { customBtnsEnabled && stock !== 0 && (customStats.cpu.options.length > 1) && hasAuth &&
                <>
                  <div className="customPackBtnWrapper">
                    <div className={`btn btn-success customPackBtn me-1 ${customStats.cpu.index === (customStats.cpu.options.length-1) ? 'disabled' : ''}`} onClick={(event) => onClickIncrease(event, 1)}>+</div>
                    <div className={`btn btn-success customPackBtn ${customStats.cpu.index === 0 ? 'disabled' : ''}`} onClick={(event) => onClickDecrease(event, 1)}>-</div>
                  </div>
                </>
              }
            </li>
            <li className="position-relative">
              <Icon.Speedometer className="me-1 fs-5" /> 
              <p className="fs-6">{t('Ticks', { ticks: (customStats && customStats.disk ? customStats.disk.options[customStats.disk.index] : value.disk) })}</p>
            </li>
            {/*<li>
              <Icon.Ethernet className="me-1 fs-5"/>
              <p className="fs-6">{t('GBNetwork', { networkspeed: value.network })}</p>
            </li>*/}
            { 
              process.env.REACT_APP_DDOS_ENABLED === 'true' &&
                <li>
                  <Icon.ShieldLockFill className="me-1 fs-5 text-success"/>
                  <p className="fs-6 text-success">{t('DDoSProtection')}</p>
                </li>
            }
          </ul>
          {
            !noBtn && <a href={value.link} className="btn btn-success btn-lg w-100 mt-auto">{t('OrderNow')}</a>
          }
          </div>
        </div>
        </>
        break;


    // ---------------------
    // ARK Survival
    // ---------------------
    case '2':
      template =
        <>
        <div onClick={goToPackage} className={`product-card pw card-wrapper ${className !== undefined ? className : ''} ${value.size !== undefined ? `col-${value.size}` : 'col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3'}`}>
          <div className={`card card-body product d-flex flex-column ${video ? 'video' : 'cursor-hand'} ${className ? className : ''}`}>
              { packageVideo }
              { packageDiscountRibbon }
              { packageStockBadge }
           <h3 className={`card-header ${value.iconclass} ${video ? 'video-header' : ''}`}>
              {t(value.titlelang)} 
              { productTitle }
           </h3>
           { packagePrice }
           <p className={`m-1 ${video ? 'ms-3' : ''}`}>
             {shorten ? strShorten(t(value.descriptionlang), SHORTENLENGTH) : t(value.descriptionlang)}
           </p>
           <ul className={`m-2 ${video ? 'ms-4 mt-3' : ''}`}>
            <li>
              <Icon.PeopleFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('Slots', { slots: value.slots })}</p>
            </li>
            <li className="position-relative">
              <Icon.Memory className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBRAM', { ram: (value.stock !== 0 && customStats && customStats.memory ? customStats.memory.options[customStats.memory.index] : value.ram) })}</p>&nbsp;
              { hasAuth && customStats && stock !== 0 && (customStats.memory.options.length > 1) && customStats.memory && 
                <div className="text-white tooltip">
                  <Icon.QuestionCircleFill />
                    <div className="right danger">
                      {t('Options')}: {customStats.memory.options.join(' GB, ')} GB
                    </div>
                </div>
              }
              { customBtnsEnabled && stock !== 0 && (customStats.memory.options.length > 1) && hasAuth &&
                <>
                  <div className="customPackBtnWrapper">
                    <div className={`btn btn-success customPackBtn me-1 ${customStats.memory.index === (customStats.memory.options.length-1) ? 'disabled' : ''}`} onClick={(event) => onClickIncrease(event, 0)}>+</div>
                    <div className={`btn btn-success customPackBtn ${customStats.memory.index === 0 ? 'disabled' : ''}`} onClick={(event) => onClickDecrease(event, 0)}>-</div>
                  </div>
                </>
              }
            </li>
            <li className="position-relative">
              <Icon.CpuFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('CPUCount', { cpucount: (value.stock !== 0 && customStats && customStats.cpu ? customStats.cpu.options[customStats.cpu.index] : value.cpu), clockspeed: value.cputype })}</p>&nbsp;
              { hasAuth && customStats && stock !== 0 && (customStats.cpu.options.length > 1) && customStats.cpu && 
                <div className="text-white tooltip">
                  <Icon.QuestionCircleFill />
                    <div className="right danger">
                      {t('Options')}: {customStats.cpu.options.join(' stk, ')} stk
                    </div>
                </div>
              }
              { customBtnsEnabled && stock !== 0 && (customStats.cpu.options.length > 1) && hasAuth &&
                <>
                  <div className="customPackBtnWrapper">
                    <div className={`btn btn-success customPackBtn me-1 ${customStats.cpu.index === (customStats.cpu.options.length-1) ? 'disabled' : ''}`} onClick={(event) => onClickIncrease(event, 1)}>+</div>
                    <div className={`btn btn-success customPackBtn ${customStats.cpu.index === 0 ? 'disabled' : ''}`} onClick={(event) => onClickDecrease(event, 1)}>-</div>
                  </div>
                </>
              }
            </li>
            <li className="position-relative">
              <Icon.DeviceSsdFill className="me-1 fs-5" /> 
              <p className="fs-6">{t('GBDisk', { disk: (value.stock !== 0 && customStats && customStats.disk ? customStats.disk.options[customStats.disk.index] : value.disk) })}</p>&nbsp;
              { hasAuth && customStats && stock !== 0 && (customStats.disk.options.length > 1) && customStats.disk && 
                <div className="text-white tooltip">
                  <Icon.QuestionCircleFill />
                    <div className="right danger">
                      {t('Options')}: {customStats.disk.options.join(' GB, ')} GB
                    </div>
                </div>
              }
              { customBtnsEnabled && stock !== 0 && (customStats.disk.options.length > 1) && hasAuth &&
                <>
                  <div className="customPackBtnWrapper">
                    <div className={`btn btn-success customPackBtn me-1 ${customStats.disk.index === (customStats.disk.options.length-1) ? 'disabled' : ''}`} onClick={(event) => onClickIncrease(event, 2)}>+</div>
                    <div className={`btn btn-success customPackBtn ${customStats.disk.index === 0 ? 'disabled' : ''}`} onClick={(event) => onClickDecrease(event, 2)}>-</div>
                  </div>
                </>
              }
            </li>
            {/*<li>
              <Icon.Ethernet className="me-1 fs-5"/>
              <p className="fs-6">{t('GBNetwork', { networkspeed: value.network })}</p>
            </li>*/}
            { 
              process.env.REACT_APP_DDOS_ENABLED === 'true' &&
                <li>
                  <Icon.ShieldLockFill className="me-1 fs-5 text-success"/>
                  <p className="fs-6 text-success">{t('DDoSProtection')}</p>
                </li>
            }
          </ul>
          {
            !noBtn && <a href={value.link} className="btn btn-success btn-lg w-100 mt-auto">{t('OrderNow')}</a>
          }
          </div>
        </div>
        </>
        break;
  }

    return (
      !noAnim ? (
          <motion.div
            variants={{
              hidden: { opacity: 0, y: 100 },
              visible: { opacity: 1, y: 0 }
            }}
            initial="hidden"
            animate="visible"
            className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
            transition={{ duration: 0.5, delay: delay }}
          > 
            {template}
          </motion.div>
        ) : (
          <>
            {template}
          </>
        )
    );
}

export { ProductWrapper, StockColor };